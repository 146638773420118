import React, { useState, useEffect, useRef } from 'react';
import { Container, Col, Card, Row, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import { DBuploadDel } from '../../api/back/DBuploadDel';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import $ from 'jquery';
import 'datatables.net-responsive-dt';
import 'datatables.net-bs5'; 
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { dataTableLang } from '../../utils/dataTableLang';
import { createRoot } from 'react-dom/client';

DataTable.use(DT);

interface InfoFormat {
  id: number;
  attributes: {
    files: any;
    files_en: any;
    name_en: string;
    name_zh: string;
    display: boolean;
    image?: { data?: { id: number } };
    system?: { data?: { attributes: { name_zh: string } } };
  };
}

const BackPinfo: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<InfoFormat[]>([]);
  const [category, setCategoryData] = useState<InfoFormat[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [searchName, setSearchName] = useState<string>('');
  const tableRef = useRef<any>(null);

  const columns = [
    { title: '類別', data: 'category' },
    { title: '中文名稱', data: 'name_zh' },
    { title: '英文名稱', data: 'name_en' },
    {
      title: '呈現狀態',
      data: 'display',
      render: (display: boolean) =>
        display
          ? '<span >開啟</span>'
          : '<span class="text-danger">關閉</span>',
    },
    { title: '操作', data: null },
  ];

  const fetchCategories = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearch('systems', 'populate=*', searchStr, 'sort_ASC');
      setCategoryData(res.info);
    } catch (error) {
      console.error('Fetching categories failed:', error);
    }
  };

  const isChinese = (text: string) => {
    return /[\u4e00-\u9fff]/.test(text); // 判斷是否包含中文字符
  };

  const showData = async () => {
    try {
      const searchStr: any = { nonDel: { type: 'basic', operator: 'eq', text: true } };

      if (selectedCategoryId && selectedCategoryId !== "none") {
        searchStr.system = { type: 'advance', operator: 'eq', text: selectedCategoryId, other: ["system", "name_en"] };
      }

      if (selectedStatus) {
        searchStr.display = { type: 'basic', operator: 'eq', text: selectedStatus };
      }

      if (searchName) {
        const trimName = searchName.trim(); 
        if (isChinese(trimName)) {
          searchStr.name_zh = { type: 'basic', operator: 'contains', text: trimName };
        } else {
          searchStr.name_en = { type: 'basic', operator: 'contains', text: trimName };
        }
      }

      const res = await DBsearch('products', 'populate=*', searchStr);
      if (res.info.length > 0) {
        setData(res.info);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Fetching products failed:', error);
    }
  };

  const editMode = (id?: number) =>{
    navigate(`/dashboard/backPEdit/`, { state: { id, dbName: 'products' } });
  } 

  const deleteMode = async (
    id: number,
    imgId?: number,
    fileId?: number,
    fileEnId?: number
  ) => {
    const confirm = await Swal.fire({
      title: '您確定要刪除這個項目嗎？',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '是的，刪除它！',
      cancelButtonText: '取消',
    });
    if (confirm.isConfirmed) {
      try {
        if (imgId) await DBuploadDel(imgId);
        if (fileId) await DBuploadDel(fileId);
        if (fileEnId) await DBuploadDel(fileEnId);
        await DBupdate('products', id, { data: { nonDel: false } });
        showData();
        Swal.fire('刪除成功', '', 'success');
      } catch (error) {
        //Swal.fire('刪除失敗', error.message, 'error');
      }
    }
  };

  useEffect(() => {
    fetchCategories();
    showData();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      $(tableRef.current).DataTable({
        responsive: true,
        data: data.map((item) => ({
          category: item.attributes.system?.data?.attributes.name_zh || '無',
          name_zh: item.attributes.name_zh,
          name_en: item.attributes.name_en,
          display: item.attributes.display,
          id: item.id,
          imgId: item.attributes.image?.data?.id,
          fileId: item.attributes.files?.data?.id,
          fileEnId: item.attributes.files_en?.data?.id,
        })),
        columns,
        ordering: false,
        searching:false,
        destroy: true,
        pageLength: 10,
        columnDefs: [
          { targets: [0, 3, 4], className: 'text-center' },
        ],
        language: {
          ...dataTableLang,
          emptyTable: '目前沒有資料',
        },
        headerCallback: function (thead) {
          $(thead).addClass('table-dark');
        },
        createdRow: (row: any, rowData: any) => {
          const { id, imgId, fileId, fileEnId } = rowData;
          const div = document.createElement('div');
          const root = createRoot(div);
    
          root.render(
            <>
              <Button
                variant="outline-primary"
                className="mx-1"
                onClick={() => editMode(id)}
              >
                <i className="bi bi-highlighter"></i>
              </Button>
              <Button
                variant="outline-danger"
                className="mx-1"
                onClick={() => deleteMode(id, imgId, fileId, fileEnId)}
              >
                <i className="bi bi-trash"></i>
              </Button>
            </>
          );
    
          $(row).find('td').eq(4).empty().append(div);
        },
        drawCallback: function () {
          $('div.dt-info').addClass('text-dark p-0');
          $('div.dt-layout-row').addClass('row mt-2 justify-content-between');

          $('div.dt-layout-start').addClass('col-md-auto me-auto');
          $('div.dt-layout-end').addClass('col-md-auto ms-auto');

          $('div.dt-paging nav').addClass('btn-group');

          setTimeout(() => {
            const buttons = $('button.dt-paging-button');
        
            buttons.each(function () {
              $(this).addClass('btn btn-secondary'); 
              if ($(this).hasClass('current')) {
                $(this).addClass('disabled').prop('disabled', true);
              } 
        
            });

          }, 0);
        },
      });
      $('div.dt-length').hide();
    }

  }, [data]);

  return (
    <Container fluid>
      <Col xs={12} lg={2}>
        <h4 className="text-secondary">
          <b>產品資料</b>
        </h4>
      </Col>
      <Card className="border shadow-sm bg-light mt-3">
        <Card.Body>
          <Row>
            <Col xs={6}>
              <Button variant="outline-dark" onClick={() => editMode()}>
                + 新增產品資料
              </Button>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                variant="warning"
                onClick={() => navigate('/dashboard/backPsort/')}
              >
                <i className="bi bi-arrows-expand"></i> 選擇產品排序
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={3}>
              <Form.Select
                value={selectedCategoryId ?? ''}
                onChange={(e) => setSelectedCategoryId(e.target.value)}
              >
                <option value="">選擇類別</option>
                {category.map((cat) => (
                  <option key={cat.id} value={cat.attributes.name_en}>
                    {cat.attributes.name_zh}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={3}>
              <Form.Select
                value={selectedStatus ?? ''}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">選擇狀態</option>
                <option value="true">開啟</option>
                <option value="false">關閉</option>
              </Form.Select>
            </Col>
            <Col xs={4}>
              <Form.Control
                type="text"
                placeholder="輸入產品名稱"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </Col>
            <Col xs={2}>
              <Button variant="secondary" onClick={showData}>
                <i className="bi bi-search"></i> 搜尋
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <table ref={tableRef} className="table table-bordered table-hover" style={{ width: '100%' }} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default BackPinfo;