import { STRAPI, STRAPI_token } from '../utils/config';
import axios from 'axios';
import qs from 'qs';
import { transformKeys } from '../utils/transofrmKeys';

interface DBResult {
  info: any[];
  pageMeta?: any;
  url?: any;
}

export const DBsearchSingle = async (
  DBname: string,
  populateConn?: string,
  searchStr?: any,
  type?: string
): Promise<DBResult> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${STRAPI_token}`,
      },
    };

    const query = qs.stringify({
      filters: transformKeys(searchStr, DBname).transformed,
    }, { encodeValuesOnly: true });

    const url = populateConn
      ? `${STRAPI}/api/${DBname}?${query}&${populateConn}`
      : `${STRAPI}/api/${DBname}?${query}`;

    const response = await axios.get(url, config);

    return { info: response.data};
  } catch (err) {
    console.error('Error setting up sync with remote database:', err);
    throw new Error('同步設置失敗');
  }
};
