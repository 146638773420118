import React, { useEffect, useState, useRef } from 'react';
import { Container, Col, Card, Form, Button, Row } from 'react-bootstrap';
import { STRAPI } from '../../utils/config';
import Swal from 'sweetalert2';
import { DBupload } from '../../api/back/DBupload';
import { DBsearchSingle } from '../../api/DBsearchSingle';

const BackPdf: React.FC = () => {
  const [selectedFileZH, setSelectedFileZH] = useState<File | null>(null);
  const [selectedFileEN, setSelectedFileEN] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const fileInputRefZH = useRef<HTMLInputElement | null>(null);
  const fileInputRefEN = useRef<HTMLInputElement | null>(null);

  const [finishFile, setFinishFile] = useState<{ fileName: string, fileUrl: string, uptime: string }>({ fileName: '', fileUrl: '', uptime: '' });
  const [finishFileEN, setFinishFileEN] = useState<{ fileName: string, fileUrl: string, uptime: string }>({ fileName: '', fileUrl: '', uptime: '' });

  const showInfo = async () => {
    try {
      const res: any = await DBsearchSingle('pdf-download', 'populate=*');
      console.log(res);
      // Zh檔案
      let file="",fileName="",formattedUptime="";
      let fileEN="",fileNameEN="",formattedUptimeEN="";
      if(res.info.data.attributes.pdfFile.data){
        file = STRAPI + res.info.data.attributes.pdfFile.data.attributes.url;
        fileName = res.info.data.attributes.pdfFile.data.attributes.name;
        formattedUptime = res.info.data.attributes.pdfFile.data.attributes.updatedAt.split('T')[0].replace(/-/g, '/');
      }
      
      // En檔案
      if(res.info.data.attributes.pdfFile_en.data){
        fileEN = STRAPI + res.info.data.attributes.pdfFile_en.data.attributes.url;
        fileNameEN = res.info.data.attributes.pdfFile_en.data.attributes.name;
        formattedUptimeEN = res.info.data.attributes.pdfFile_en.data.attributes.updatedAt.split('T')[0].replace(/-/g, '/');
      }

      setFinishFile({ fileName: fileName, fileUrl: file, uptime: formattedUptime });
      setFinishFileEN({ fileName: fileNameEN, fileUrl: fileEN, uptime: formattedUptimeEN });

    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    }
  }

  const downloadPDF = (type:string) => {

    if(finishFile.fileUrl !== "" && type==="en"){
      window.open(finishFileEN.fileUrl, "_blank");
    }
    if(finishFileEN.fileUrl !== "" && type==="zh"){
      window.open(finishFile.fileUrl, "_blank");
    }
    /*if (finishFile.fileUrl !== "") {
      window.open(finishFile.fileUrl, "_blank");
    }
    if (finishFileEN.fileUrl !== "") {
      window.open(finishFileEN.fileUrl, "_blank");
    }*/
  }

  const handleFileChangeZH = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFileZH(event.target.files[0]);
    }
  };

  const handleFileChangeEN = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFileEN(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFileZH && !selectedFileEN) {
      setUploadStatus('請選擇要上傳的檔案');
      return;
    }

    const formDataZH = selectedFileZH ? new FormData() : null;
    const formDataEN = selectedFileEN ? new FormData() : null;

    if (formDataZH) {
      formDataZH.append('files', selectedFileZH!);
      formDataZH.append('path', 'pdfDownload');
    }

    if (formDataEN) {
      formDataEN.append('files', selectedFileEN!);
      formDataEN.append('path', 'pdfDownload');
    }

    try {
      const res = await DBupload(formDataZH, formDataEN, 'pdf-download');
      if (res.status === true) {
        Swal.fire({
          icon: "success",
          title: "上傳成功",
          showConfirmButton: false,
          timer: 1500
        });

        if (fileInputRefZH.current) {
          fileInputRefZH.current.value = '';
        }
        if (fileInputRefEN.current) {
          fileInputRefEN.current.value = '';
        }
        setSelectedFileZH(null);
        setSelectedFileEN(null);
        showInfo();
      }
    } catch (error) {
      console.error('Error:', error);
      setUploadStatus('上傳有誤');
    }
  };

  useEffect(() => {
    showInfo();
  }, []);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={12}>
          <h4 className='text-secondary'><b>產品型錄</b></h4>
          <p className='text-secondary'>需要上傳中文和英文的產品型錄PDF檔案，前台會根據語系呈現對應上傳的pdf文件。</p>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='shadow-sm bg-light border'>
          <Card.Body>
            {/*內容*/}
            <Row className='mb-3'>
              <Col>
                <p>
                  <b className='text-dark'>查看目前中文文件：</b>
                  <button type="button" className='btn btn-link text-primary fw-normal text-decoration-underline' onClick={()=>downloadPDF('zh')} >{finishFile.fileName}</button>
                </p>
              </Col>
              <Col className='text-end'>
                <span className='text-secondary'>更新時間：{finishFile.uptime}</span>
              </Col>
            </Row>
            <Row className='border-bottom mb-3'>
              <Col>
                <p>
                  <b className='text-dark'>查看目前英文文件：</b>
                  <button type="button" className='btn btn-link text-primary fw-normal text-decoration-underline' onClick={()=>downloadPDF('en')} >{finishFileEN.fileName}</button>
                </p>
              </Col>
              <Col className='text-end'>
                <span className='text-secondary'>更新時間：{finishFileEN.uptime}</span>
              </Col>
            </Row>
            <Row className='mb-5'>
              <Col md={6}>
                <Form.Group controlId="formFileZH" className="mb-3">
                  <Form.Label className='text-dark'><b>選擇上傳中文文件</b></Form.Label>
                  <Form.Control type="file" ref={fileInputRefZH} onChange={handleFileChangeZH} />
                </Form.Group>
                <p className='text-danger'>{uploadStatus}</p>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formFileEN" className="mb-3">
                  <Form.Label className='text-dark'><b>選擇上傳英文文件</b></Form.Label>
                  <Form.Control type="file" ref={fileInputRefEN} onChange={handleFileChangeEN} />
                </Form.Group>
                <p className='text-danger'>{uploadStatus}</p>
              </Col>
            </Row>
            
            <Row>
              <Col xs={12} className='text-center'>
                <Button variant="outline-dark" className='px-4' onClick={handleUpload}>
                  &emsp;送&ensp;出 &emsp;
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default BackPdf;