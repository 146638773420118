import React, { useState } from 'react';
import { Container,Col,Card,Row,Button } from 'react-bootstrap';
import { EyeFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import { DBchkPwd } from '../../api/back/DBchkPwd';
import { getToken } from '../../utils/jwtUtils';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

const BackChpwd: React.FC = () => {
  let navigate = useNavigate(); 

  const [pwd,setPwd]=useState<{oldPwd:string,newPwd:string,confirmPwd:string}>({oldPwd:"",newPwd:"",confirmPwd:""}); 
  const [showPassword, setShowPassword] = useState<{ oldPwd: boolean; newPwd: boolean; confirmPwd: boolean }>({
    oldPwd: false,
    newPwd: false,
    confirmPwd: false,
  });

  const handleSubmit = async(e: React.FormEvent) => {
    e.preventDefault();
    // 驗證密碼
    if (pwd.newPwd !== pwd.confirmPwd) {
      alert('新密碼不一致，請重新輸入');
      return;
    }else{
      try {
        const jwt=getToken();
        const res = await DBchkPwd(pwd,jwt);
        if(res.info.status===200){
          Cookies.set('jwt', res.info.data.jwt, { 
            expires: 7,
            secure: true,
            sameSite: 'Strict'
          });
          Swal.fire({
            icon: "success",
            title: "success",
            text: "修改密碼成功",
            showConfirmButton: false,
            timer: 1500
          });
          navigate('/dashboard');
        }
        
        
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "修改密碼有誤",
          showConfirmButton: false,
          timer: 1500
        });
        console.error('Fetching product name failed:', error);
        return null;
      }
      

    }
  };

  const toggleShowPassword = (field: 'oldPwd' | 'newPwd' | 'confirmPwd') => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={2}>
          <h4 className='text-secondary'><b>修改密碼</b></h4>
          <p className='text-secondary'>修改管理者的密碼</p>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>

            <div className="mb-3 position-relative">
              <label className="form-label text-dark">
                <b>原密碼 *</b>
              </label>
              <input type={showPassword.oldPwd ? 'text' : 'password'}
                className="form-control" placeholder="請輸入原本的密碼"
                value={pwd.oldPwd} onChange={(e) => setPwd({ ...pwd, oldPwd: e.target.value })} />
              <EyeFill onClick={() => toggleShowPassword('oldPwd')} role='button'
                className="text-dark position-absolute end-0 top-50 mt-1 me-3"
                style={{ cursor: 'pointer', width: '25', height: '25' }} />
            </div>

            <div className="position-relative">
              <label className="form-label text-dark">
                <b>新密碼 *</b><span className='text-secondary'>&ensp;密碼輸入需超過6個字以上</span>
              </label>
              <input type={showPassword.newPwd ? 'text' : 'password'}
                className="form-control" placeholder="請輸入新的密碼"
                value={pwd.newPwd} onChange={(e) => setPwd({ ...pwd, newPwd: e.target.value })} />
              <EyeFill onClick={() => toggleShowPassword('newPwd')} role='button'
                className="text-dark position-absolute end-0 top-50 mt-1 me-3"
                style={{ cursor: 'pointer', width: '25', height: '25' }} />
            </div>

            <div className="mb-3 position-relative">
              <label className="form-label text-dark">
                <b></b>
              </label>
              <input type={showPassword.confirmPwd ? 'text' : 'password'}
                className="form-control" placeholder="請再次輸入一次新密碼"
                value={pwd.confirmPwd} onChange={(e) => setPwd({ ...pwd, confirmPwd: e.target.value })} />
              <EyeFill onClick={() => toggleShowPassword('confirmPwd')} role='button'
                className="text-dark position-absolute end-0 top-50 me-3"
                style={{ cursor: 'pointer', width: '25', height: '25' }} />
            </div>

            <Row className='py-2'>
              <Col xs={12} className='text-center'>
                <Button variant="outline-dark" className="px-5 " onClick={handleSubmit}>送出</Button>
              </Col>
            </Row>

          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

export default BackChpwd