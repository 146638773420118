import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProductCateTrans, ProductNameTrans } from '../../apiTranslate/productTrans';
import { DBsearch } from '../../api/DBsearch';
import { STRAPI } from '../../utils/config';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

interface ProductListProps {
    products: any;
}

interface paramNameFromat {
    systemName: string;
    productName: string;
}

const ProductDetail: React.FC<ProductListProps> = ({ products }) => {
    const { i18n } = useTranslation();
    const params = useParams();
    const [paramName, setParamName] = useState<paramNameFromat>({ systemName: '', productName: '' });
    const [categoryName, setCategoryName] = useState<string>('');
    const [productName, setProductName] = useState<string>('');

    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    const [file, setFile] = useState<{ zhFile: string, enFile: string }>({ zhFile: '', enFile: '' });

    const showCategory = async () => {
        try {
            const translatedCategoryName = await ProductCateTrans('category',paramName.systemName, i18n.language);
            setCategoryName(translatedCategoryName);
        } catch (error) {
            console.error("Error fetching category name:", error);
        }
    }

    const showProduct = async () => {
        try {
            const translatedProductName = await ProductNameTrans(paramName.productName, i18n.language);
            setProductName(translatedProductName);
        } catch (error) {
            console.error("Error fetching product name:", error);
        }
    }

    const showPDF = async (name: string) => {
        try {
            const searchStr = { name_en: { type: 'basic', operator: 'eq', text: name } };
            const res = await DBsearch('products', 'populate=*', searchStr);

            const zhFile = res.info[0].attributes.files.data ? STRAPI + res.info[0].attributes.files.data.attributes.url : '';
            const enFile = res.info[0].attributes.files_en.data ? STRAPI + res.info[0].attributes.files_en.data.attributes.url : '';

            setFile({ zhFile, enFile });
        } catch (error) {
            console.error('Fetching product name failed:', error);
            return null;
        }
    }

    const downloadPDF = () => {
        let pdfFile;

        if (i18n.language === 'zh') {
            pdfFile = file.zhFile || file.enFile;
        } else if (i18n.language === 'en') {
            pdfFile = file.enFile || file.zhFile;
        }

        if (pdfFile) {
            window.open(pdfFile, "_blank");
        } else {
            alert('No file available for download.');
        }
    }

    useEffect(() => {
        if (params.productSystem && params.productName) {
            const systemName = params.productSystem.replace(/_/g, ' ');            
            const nameEnTmp = params.productName.replace(/__/g, '/');
            const productName = nameEnTmp.replace(/_/g, ' ');
            setParamName({ systemName, productName });
        }
    }, [params.productName]);

    useEffect(() => {
        if (paramName.systemName && paramName.productName) {
            showPDF(paramName.productName);
            showCategory();
            showProduct();
        }
    }, [paramName, i18n.language]);

    return (
        <>
            <Container className='main-content'>
                <Row className="mt-3">
                    <Col sm={12} md={8}>
                        <h4>{productName}</h4>
                    </Col>
                    <Col sm={12} md={4} className="d-flex justify-content-md-end align-items-center">
                        <Button variant="outline-dark" className="btn-sm-print" onClick={downloadPDF}>Download or Print PDF</Button>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <h6 className='text-secondary'>{categoryName}</h6>
                    </Col>
                </Row>
                <Row className='pb-5'>
                    <Col className='col-md-12 mb-2'>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                            <div className="d-flex justify-content-end bg-default mb-3 shadow-sm border">
                                <div className="p-2"><ZoomInButton /></div>
                                <div className="p-2"><ZoomOutButton /></div>
                                <div className="p-2 bg-warning"><ZoomPopover /></div>
                            </div>
                            <div className='shadow-sm border'>
                                <div className='pdf-size'>
                                {
                                    !file.zhFile ? (
                                        <p className='text-center'>無上傳任何資料</p>
                                    ) : i18n.language === 'zh' ? (
                                        <Viewer fileUrl={file.zhFile} plugins={[zoomPluginInstance]} />
                                    ) : i18n.language === 'en' && !file.enFile ? (
                                        <Viewer fileUrl={file.zhFile} plugins={[zoomPluginInstance]} />
                                    ) : (
                                        <Viewer fileUrl={file.enFile} plugins={[zoomPluginInstance]} />
                                    )
                                }
                                </div>
                            </div>
                        </Worker>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ProductDetail;