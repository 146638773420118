import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/backPage.css';
import { DBLogin } from '../../api/back/DBLogin';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

const LoginBack = () => {
  let navigate = useNavigate(); 

  const [identifier, setIdentifier] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleLogin = async () => {
    if(identifier && password){
      try {
        const res = await DBLogin(identifier,password);
        const data = res.info;

        if (data.jwt) {
          Cookies.set('jwt', data.jwt);
          navigate('/dashboard');
        }
        
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "登入失敗，輸入有誤。",
          showConfirmButton: false,
          timer: 1500
        });
          console.error('Fetching product name failed:', error);
          return null;
      }

    }else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "未填寫完整的登入資料",
        showConfirmButton: false,
        timer: 1500
      });

    }
    
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className='container-fluid login-page pt-5'>
      <div className="row pt-5">
        <div className='offset-md-4 col-md-4 mt-5'>
          <div className='card mt-5 pt-5 shadow-lg'>
            <h4 className='text-center'><b>後台管理</b></h4>
            <h4 className='text-center'></h4>
            <div className='card-body'>
              <div className='mb-3'>
                <input type="text" className='form-control' placeholder='請輸入登入帳號' value={identifier} onChange={(e) => setIdentifier(e.target.value)} onKeyDown={handleKeyDown} />
              </div>
              <div className='mb-4'>
                <input type='password' className='form-control' placeholder='請輸入登入密碼' value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />
              </div>
              <button className='btn btn-primary w-100 mt-2 mb-2' onClick={handleLogin}>登入</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBack;
