import { STRAPI, STRAPI_token } from '../../utils/config';
import axios from 'axios';

export const DBuploadDel = async (id:any) => {
  try {

    let uploadStatus=1;
    if(id){
      const delInfo = await axios.delete(`${STRAPI}/api/upload/files/${id}`, {
          headers: {
              Authorization: `Bearer ${STRAPI_token}`
          },
      });
      if (delInfo.status === 200) {
          console.log('File deleted successfully');
          uploadStatus=0;
      } else {
          throw new Error('檔案刪除失敗');
      }
    }

    if (uploadStatus===0) {
      return { status:true };
    } else {
        return { status:false };
    }
    
  } catch (error:any) {
    console.error('Error:', error.message);
    return { status: false, message: error.message };
  }
};