import { STRAPI, STRAPI_token } from '../utils/config';
import axios from 'axios';

interface DBResult {
    info: any;
    error?: any;
}
export const DBupdate = async (DBname:string,id:any,updateStr:any): Promise<DBResult> => {   
    try {

        const config = {
            headers: {
                Authorization: `Bearer ${STRAPI_token}`
            }
        };

        const url=`${STRAPI}/api/${DBname}/${id}`;
        const response = await axios.put(url,updateStr,config);
        return {info:response.status};
        
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            let errMSG="";
            if(errMSG="This attribute must be unique"){
                errMSG="已有重複內容。";
            }
            return { info: err.response.status, error: errMSG };
        }
        console.error('Error setting up sync with remote database:', err);
        return { info: 500, error: '同步設置失敗' };
    }
};
