import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

interface CategoryListProps {
    categories: any;
    onSelectCategory: (slug: string, title: string) => void;
    selectedCategory: string | null;
    //products: { [key: string]: any[] };
    products: any;
}

const CategoryList: React.FC<CategoryListProps> = ({ categories, onSelectCategory, selectedCategory, products }) => {
    const { i18n } = useTranslation();
    const navigate = useNavigate(); 

    const handleCardClick = (systemSlug:string,productSlug: string) => {
        const sysSlugTmp = systemSlug.replace(/\s+/g, '_');
        const sysSlug = sysSlugTmp.replace(/\//g, '__');
        const urlSlugTmp = productSlug.replace(/\s+/g, '_');
        const urlSlug = urlSlugTmp.replace(/\//g, '__');
        const newPath = `/product/${sysSlug}/${urlSlug}`;
        navigate(newPath);
    };

    return (
        <>
        <Accordion>
            {
                categories.map((category: any, index: number) => {
                    const { name_en, name_zh, slug } = category.attributes;
                    const title = i18n.language === 'en' ? name_en : name_zh;

                    return (
                        <Accordion.Item eventKey={index.toString()} key={category.id}>
                            <Accordion.Header className="text-bg-secondary" onClick={() => onSelectCategory(slug, title)}>
                                <span className='text-white'>{title}</span>
                            </Accordion.Header>
                            <Accordion.Body className='p-0'>
                                {products.length > 0 ? (
                                    <>
                                    {products.map((item: any) => {
                                        const { name_zh, name_en } = item.attributes;
                                        const sysName=item.attributes.system.data.attributes.name_en;
                                        return (
                                            <button key={item.id} className='btn btn-light btn-accordion text-dark w-100' onClick={() => handleCardClick(sysName,name_en)}>
                                                {i18n.language === 'en' ? name_en : name_zh}
                                            </button>
                                        );
                                    })}
                                    </>
                                ) : (
                                    <p>No products found for this category.</p>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                })
            }
        </Accordion>
        </>
    );
};

export default CategoryList;
