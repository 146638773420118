import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom'; 
import './NavbarFront.css';
import { DBsearch } from '../../api/DBsearch';
import styles from './NavbarFront.module.css'; 
import { STRAPI } from '../../utils/config';

const NavbarFront: React.FC = () => {
  const { t, i18n } = useTranslation(); 
  const location = useLocation(); 
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [materials, setMaterials] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [logoUrl1, setLogoUrl1] = useState<string | null>(null);
  const [logoUrl2, setLogoUrl2] = useState<string | null>(null);

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  // 切換下拉選單，保證其他選單會自動收起
  const toggleDropdown = (dropdownKey: string) => {
    setOpenDropdown(prev => (prev === dropdownKey ? null : dropdownKey)); // 點擊同一選單則關閉，點擊其他的則切換
  };


  const currentLanguage = i18n.language;
  const toggleLanguage = (): void => {
    const newLanguage = currentLanguage === 'en' ? 'zh' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const buttonLabel = currentLanguage === 'en' ? '中文' : 'English';
  
  const showMaterial = async()=>{
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true },display:{type: 'basic', operator: 'eq', text: true} };
      const res = await DBsearch('materials','populate=*',searchStr, 'sort_ASC');
      setMaterials(res.info);
    } catch (error) {
      console.error('Fetching categories failed:', error);
    }
  }

  const showCategory = async()=>{
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true },display:{type: 'basic', operator: 'eq', text: true} };
      const res = await DBsearch('systems','populate=*',searchStr, 'sort_ASC');
      setCategories(res.info);
    } catch (error) {
      console.error('Fetching categories failed:', error);
    }
  }

  const fetchLogo = async () => {
    try {
      const searchStr = { headerShow:{type: 'basic', operator: 'eq', text: true},headerNum:{type: 'basic', operator: 'eq', text: 1} };
      const res = await DBsearch('logos', 'populate=image',searchStr);
      if (res.info.length > 0 && res.info[0].attributes.image) {
        const logoUrl = res.info[0].attributes.image.data.attributes.url;
        setLogoUrl1(STRAPI+logoUrl);
      }
      const searchStr2 = { headerShow:{type: 'basic', operator: 'eq', text: true},headerNum:{type: 'basic', operator: 'eq', text: 2} };
      const res2 = await DBsearch('logos', 'populate=image',searchStr2);
      if (res2.info.length > 0 && res2.info[0].attributes.image) {
        const logoUrl = res2.info[0].attributes.image.data.attributes.url;
        setLogoUrl2(STRAPI+logoUrl);
      }
    } catch (error) {
      console.error('Fetching logo failed:', error);
    }
  };


  useEffect(() => {
    showMaterial();
    showCategory();
    fetchLogo();
    switch(location.pathname) {
      case '/':
        setActiveKey('home');
        break;
      case '/about':
        setActiveKey('about');
        break;
      case '/new':
          setActiveKey('new');
          break;
      case '/product':
        setActiveKey('product');
        break;
      case '/contact':
        setActiveKey('contact');
        break;
      case '/download':
        setActiveKey('download');
        break;
      default:
        setActiveKey(undefined); 
        break;
    }
  }, [location.pathname,setCategories]);

  return (
    
    <Navbar expand="lg" bg="dark" variant="dark" className="navbar-custom shadow">
      <Container fluid>
        <Navbar.Brand href="/">
          {logoUrl1 && (
            <img className='mx-1' src={logoUrl1} height="40" alt="Logo" />
          )}
          {
            logoUrl2 && (
              <img className='mx-1' src={logoUrl2} height="40" alt="Logo" />
            )
          }
          {
            !logoUrl1 && !logoUrl2 && (
              <img className='mx-1' src="/images/logo.png" height="40" alt="Logo" />
            )
          }
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-collapse" />
        <Navbar.Collapse id="navbar-collapse" className="justify-content-end">
          <Nav activeKey={activeKey}>
            <LinkContainer to="/">
              <Nav.Link eventKey="home" className={`px-3 ${styles.navLink} ${activeKey === 'home' ? `${styles.activeLink}` : ''}`} style={{ fontSize: '20pt' }} >
                {t('Home')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link eventKey="about" className={`px-3 ${styles.navLink} ${activeKey === 'about' ? `${styles.activeLink}` : ''}`}>
                {t('About Us')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/new">
              <Nav.Link eventKey="new" className={`px-3 ${styles.navLink} ${activeKey === 'new' ? `${styles.activeLink}` : ''}`}>
                {t('News')}
              </Nav.Link>
            </LinkContainer>
            {/* 目前的兩層product */}
            {/* <NavDropdown title={
              <>
              {t('Product')}
              <i className="bi bi-caret-down-fill ps-2"></i>
              </>
            } id="nav-dropdown-product" className={`${styles.navItem}`}>
              {categories.map((value, index) => {
                const { name_en, name_zh } = value.attributes;
                const urlName = name_en.replace(/\s+/g, '_');

                return (
                  <LinkContainer to={`/product/${urlName}`} key={index}>
                    <NavDropdown.Item eventKey={`product-category-${index}`} className={`${styles.dropdownItem}`}>
                      {i18n.language === 'en' ? name_en : name_zh}
                    </NavDropdown.Item>
                  </LinkContainer>
                );
              })}
            </NavDropdown> */}
            {/* 產品下拉選單NEW */}
            <NavDropdown
              title={
                <>
                  {t('Product')}
                  <i className="bi bi-caret-down-fill ps-2"></i>
                </>
              }
              id="nav-dropdown-product" className={`${styles.navItem}`
            }>
              {materials.map((value, index) => {
                const sysNameEn=value.attributes.name_en;
                const sysNameZh=value.attributes.name_zh;
                const systems=value.attributes.systems.data;
                return (
                  <NavDropdown key={index}
                  title={
                    <>
                      <span className="ps-2">{i18n.language === 'en' ? sysNameEn : sysNameZh}</span>
                      {openDropdown === sysNameEn ? (
                        <i className="bi bi-caret-up-fill ps-2"></i>
                      ) : (
                        <i className="bi bi-caret-down-fill ps-2"></i>
                      )}{" "}
                    </>
                  }
                  id={`nav-dropdown-${sysNameEn}`}
                  className="nav-item"
                  show={openDropdown === sysNameEn}
                  onClick={() => toggleDropdown(sysNameEn)}
                >
                  {systems && systems.map((sysInfo:any, idx:number) => {
                    const { name_en, name_zh } = sysInfo.attributes;
                    const urlName = name_en.replace(/\s+/g, '_');
                    return (
                      <LinkContainer to={`/product/${urlName}`} key={`${name_en}${index}`}>
                        <NavDropdown.Item eventKey={`${name_en}-${idx}`} className={`${styles.dropdownItem}`}>
                          {i18n.language === 'en' ? name_en : name_zh}
                        </NavDropdown.Item>
                      </LinkContainer>
                    );
                  })}
                </NavDropdown>
                );
              })}

            </NavDropdown>

            <LinkContainer to="/contact">
              <Nav.Link eventKey="contact" className={`px-3 ${styles.navLink} ${activeKey === 'contact' ? `${styles.activeLink}` : ''}`}>
                {t('Contact Us')} 
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/download">
              <Nav.Link eventKey="download" className={`px-3 ${styles.navLink} ${activeKey === 'download' ? `${styles.activeLink}` : ''}`}>
                {t('Download')} 
              </Nav.Link>
            </LinkContainer>
          </Nav>
          <Button className={`px-3 mx-2 ${styles.languageToggleBtn}`} variant="light" onClick={toggleLanguage}>
            {buttonLabel}
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default NavbarFront;
