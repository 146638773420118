import { jwtDecode,JwtPayload } from 'jwt-decode';
import Cookies from 'js-cookie';

interface DecodedToken extends JwtPayload {
  exp: number;
}

export const isTokenExpired = (token: string): boolean => {
  try {
    const decoded: DecodedToken = jwtDecode<DecodedToken>(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Invalid token:', error);
    return true;
  }
};

export const getToken = (): string | null => {
  return Cookies.get('jwt') || null;
};
