import React from 'react';
import { Toast } from 'react-bootstrap';

interface CustomToastProps {
  show: boolean;
  message: string;
  onClose: () => void;
}

const CustomToast: React.FC<CustomToastProps> = ({ show, message, onClose }) => {
  return (
    <Toast show={show} onClose={onClose} delay={3000} autohide className="bg-default text-dark" style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      minWidth: '200px'
    }}>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export default CustomToast;
