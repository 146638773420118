import React, { useEffect, useState } from 'react';
import NavbarFront from '../../components/common/NavbarFront';
import HeaderFront from '../../components/common/HeaderFront';
import { Container, Row, Col, Form, Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FooterFront from '../../components/common/FooterFront';
import { FaRegHeart,FaHeart  } from "react-icons/fa";
import { DBsearch } from '../../api/DBsearch';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { DBsearchId } from '../../api/DBsearchId';
import { DBupdate } from '../../api/DBupdate';

interface NewsInfo {
  id: number;
  attributes: {
    title_en: string;
    title_zh: string;
    display: boolean;
    sort:number;
    newsgroup:any;
    image: {
      data: Array<{ id: number }>;
    } | null;
  };
}

interface InfoFormat {
  id: number;
  attributes: {
    files: any;
    files_en: any;
    name_en: string;
    name_zh: string;
    display: boolean;
    image?: { data?: { id: number } };
    system?: { data?: { attributes: { name_zh: string } } };
  };
}

const New: React.FC = () => {
  const { t,i18n } = useTranslation();
  const navigate = useNavigate();
  
  const [data, setData] = useState<NewsInfo[]>([]);
  const [category, setCategoryData] = useState<InfoFormat[]>([]);
  
  const [tmp, setTmp] = useState<{categoryName:string, searchName:string}>({ categoryName: '', searchName: '' });
  
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [searchName, setSearchName] = useState<string>('');
  const [likedNews, setLikedNews] = useState<number[]>([]); //saved 已按讚的

  //分頁start
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const paginatedDatas = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const renderPaginationItems = () => {
    const items = [];

    // Always show the first page
    if (currentPage > 2) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );
      if (currentPage > 3) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    // Determine the range of pages to display
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    }

    // Always show the last page
    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      items.push(
        <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };
  //分頁end


  const handleLikeClick = (id: number) => {
    let updatedLikedNews;
    if (likedNews.includes(id)) {// 如果已經喜歡，則取消喜歡
      updatedLikedNews = likedNews.filter(itemId => itemId !== id);
    } else {// 如果尚未喜歡，則添加到列表中
      updatedLikedNews = [...likedNews, id];
    }

    updateDBLiked(id);
    setLikedNews(updatedLikedNews);
    Cookies.set('likedNewsItems', JSON.stringify(updatedLikedNews), { expires: 365 }); // 保存到 Cookie
  };

  const isNewsLiked = (id: number) => {
    return likedNews.includes(id);
  };

  const updateDBLiked = async (id: number) => {
    const res = await DBsearchId('news', 'populate=*', null, 'sort_ASC',id);
    const { likeNum } = res.info[0].attributes;
    const ansLiked = likeNum+1;
    const updateStr = {
      data: {
        likeNum: ansLiked
      }
    };
    await DBupdate('news', id, updateStr);
  };

  const handleTitleClick = (id:number) => {
    navigate(`/new/${id}`);
  };

  const fetchCategories = async () => {
    try {
      const searchStr: any = { nonDel: { type: 'basic', operator: 'eq', text: true },display: { type: 'basic', operator: 'eq', text: true } };

      const res = await DBsearch('newsgroups', 'populate=*', searchStr, 'sort_ASC');
      setCategoryData(res.info);
    } catch (error) {
      console.error('Fetching categories failed:', error);
    }
  };

  const handleSearchClick = () => {
    setSelectedCategoryId(tmp.categoryName);
    setSearchName(tmp.searchName); 
    showData();
  };

  const isChinese = (text: string) => {
    return /[\u4e00-\u9fff]/.test(text); // 判斷是否包含中文字符
  };

  const showData = async () => {
    try {
      const searchStr: any = { nonDel: { type: 'basic', operator: 'eq', text: true },display: { type: 'basic', operator: 'eq', text: true } };


      if (selectedCategoryId && selectedCategoryId !== "none") {
        searchStr.newsgroup = { type: 'advance', operator: 'eq', text: selectedCategoryId, other: ["newsgroup", "name_en"] };
      }

      if (searchName) {
        const trimName = searchName.trim(); 
        if (isChinese(trimName)) {
          searchStr.title_zh = { type: 'basic', operator: 'contains', text: trimName };
        } else {
          searchStr.title_en = { type: 'basic', operator: 'contains', text: trimName };
        }
      }

      const res = await DBsearch('news', 'populate=*', searchStr, 'sort_ASC');
      setData(res.info);
    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    }
  };

  useEffect(() => {
    fetchCategories();
    showData();
    const likedNewsFromCookie = Cookies.get('likedNewsItems');
    if (likedNewsFromCookie) {
      setLikedNews(JSON.parse(likedNewsFromCookie));
    }
  }, []);

  useEffect(() => {
    showData();
  }, [selectedCategoryId, searchName]);

  return(
      <>
        <NavbarFront />
        <HeaderFront />
        <Container className='main-content'>
          <Row className="justify-content-center mt-4 mb-2 bg-news-search p-3 rounded">
            <Col className='my-2'>
              <Form.Select
                value={tmp.categoryName}
                onChange={(e) => setTmp({ ...tmp, categoryName: e.target.value })}
              >
                <option value="">{t('all')}</option>
                {category.map((cat) => (
                  <option key={cat.id} value={cat.attributes.name_en}>
                    {i18n.language === 'en' ? cat.attributes.name_en : cat.attributes.name_zh}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className='my-2'>
              <Form.Control
                type="text"
                placeholder={t('searchKeyWord')}
                value={tmp.searchName}
                onChange={(e) => setTmp({ ...tmp, searchName: e.target.value })}
                className="me-2"
              />
            </Col>
            <Col className='my-2' lg={2}>
              <button
                className='btn btn-default news-search-btn w-100'
                onClick={handleSearchClick}
              >
                {t('search')}
              </button>
            </Col>
          </Row>
          <Row className='mt-4'>
            {paginatedDatas.map((item: any,index:number) => {
              const id = item.id;
              const { title_en, title_zh, newsgroup, createdAt } = item.attributes;
              const createDate = createdAt.split('T')[0].replace(/-/g, '/');

              const group_en=newsgroup.data.attributes.name_en;
              const group_zh=newsgroup.data.attributes.name_zh;
              return (
                <Col lg={4} className='mb-2' key={index}>
                <div className='d-flex align-items-center ms-2'>
                  <span className='text-secondary news-txt-sm'>{createDate}</span>
                  <span className={`news-txt-tag p-1 mx-2 rounded ${i18n.language === 'en' && 'news-txt-tag-en'}`}>
                    {i18n.language === 'en' ? group_en : group_zh}
                  </span>
                </div>
                <div className='d-flex my-2 ms-2 news-content'>
                  <a className='news-link' href='#' onClick={() => handleTitleClick(id)}>
                    {i18n.language === 'en' ? title_en : title_zh}
                  </a>
                  <button 
                    className="ms-auto" 
                    onClick={() => handleLikeClick(item.id)} 
                    style={{ background: 'none', border: 'none' }}
                  >
                    {isNewsLiked(id) ? <FaHeart size={25} color='#ff5e5e' /> : <FaRegHeart size={25} color='#cdcdcd' />}
                  </button>
                </div>
              </Col>
              );
            })}
          </Row>
          <Pagination className="d-flex justify-content-center">
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {renderPaginationItems()}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
        </Container>
        <FooterFront />
      </>
  );
}

export default New