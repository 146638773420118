import { STRAPI,STRAPI_token } from '../utils/config';
import axios from 'axios';

interface DBResult {
    info: any;
    status: number;
}
export const DBinsert = async (DBname:string,insertStr:any): Promise<DBResult> => {   
    try {

        const config = {
            headers: {
                Authorization: `Bearer ${STRAPI_token}`
            }
        };
        const url=`${STRAPI}/api/${DBname}`;
        const response = await axios.post(url,insertStr,config);
        return {info:response.data.data.id,status:response.status};
    } catch (err) {
        console.error('Error setting up sync with remote database:', err);
        throw new Error('同步設置失敗');
    }
};
