import React, { useState, useEffect, useRef } from 'react';
import { Container, Col, Card, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import { DBupsort } from '../../api/DBupsort';

import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import $ from 'jquery';
import 'datatables.net-responsive-dt';
import 'datatables.net-bs5'; 
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { dataTableLang } from '../../utils/dataTableLang';
import { createRoot } from 'react-dom/client';

DataTable.use(DT);

interface Material {
  id: number;
  attributes: {
    name_en: string;
    name_zh: string;
    display: boolean;
    sort: number;
  };
}

const BackPmaterial: React.FC = () => {
  const [data, setData] = useState<Material[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const tableRef = useRef<any>(null);

  const navigate = useNavigate();

  const columns = [
    { title: '', data: null },
    { title: '中文名稱', data: 'name_zh' },
    { title: '英文名稱', data: 'name_en' },
    {
      title: '呈現狀態',
      data: 'display',
      render: (display: boolean) =>
        display
          ? '<span >開啟</span>'
          : '<span class="text-danger">關閉</span>',
      className: 'text-center',
    },
    { title: '操作', data: null },
  ];

  const showData = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearch('materials', 'populate=*', searchStr, 'sort_ASC');
      console.log(res);
      if (res.info.length > 0) {
        setData(res.info);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const editMode = (id?: number) => {
    if (id) {
      navigate(`/dashboard/backPmEdit/`, { state: { id, dbName: 'materials' } });
    } else {
      navigate(`/dashboard/backPmEdit/`, { state: { dbName: 'materials' } });
    }
  };

  const deleteMode = async (id: number) => {
    Swal.fire({
      title: '您確定要刪除這個項目嗎？',
      text: "此操作無法撤銷！",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '是的，刪除它！',
      cancelButtonText: '取消'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updateStr = {
          data: {
            nonDel: false,
            systems: [],
          }
        };
        const res = await DBupdate('materials', id, updateStr);
        if (res.info === 200) {
          Swal.fire({
            icon: "success",
            title: "更新成功",
            showConfirmButton: false,
            timer: 1500
          });
          showData();
        }
      }
    });
  };

  const moveMaterials = async (index: number, direction: 'up' | 'down') => {
    const items: Material[] = Array.from(data);
    const [movedItem] = items.splice(index, 1);
    if (direction === 'up') {
      items.splice(index - 1, 0, movedItem);
    } else {
      items.splice(index + 1, 0, movedItem);
    }
    setData(items);

    const storedLists = items.map((item, idx) => ({
      id: item.id,
      sort: idx + 1
    }));

    try {
      await DBupsort('materials', storedLists);
      showData();
      Swal.fire({
        icon: "success",
        title: "排序更新成功",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error updating category order:', error);
      Swal.fire({
        icon: "error",
        title: "排序更新失敗",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  useEffect(() => {
    showData();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      $(tableRef.current).DataTable({
        responsive: true,
        data: data.map((item) => ({
          name_zh: item.attributes.name_zh,
          name_en: item.attributes.name_en,
          display: item.attributes.display,
          sort: item.attributes.sort,
          id: item.id,
        })),
        columns,
        ordering: false,
        searching: false,
        destroy: true,
        pageLength: 10,
        language: {
          ...dataTableLang,
          emptyTable: '目前沒有資料',
        },
        headerCallback: function (thead) {
          $(thead).addClass('table-dark');
        },
        createdRow: (row: any, rowData: any, index: number) => {
          const { id } = rowData;

          // 排序按鈕渲染
          const divSort = document.createElement('div');
          const rootSort = createRoot(divSort);
          rootSort.render(
            <>
              <Button variant='outline-light' disabled={index === 0} onClick={() => moveMaterials(index, 'up')}>
                <i className={`bi bi-arrow-up-circle-fill ${index === 0 ? 'text-secondary' : 'text-primary'}`} style={{  fontSize: '1.25rem' }}></i>
              </Button>
              <Button variant='outline-light' disabled={index === data.length - 1} onClick={() => moveMaterials(index, 'down')}>
                <i className={`bi bi-arrow-down-circle-fill ${index === data.length - 1 ? 'text-secondary' : 'text-danger'}`} style={{ fontSize: '1.25rem' }}></i>
              </Button>
            </>
          );
          $(row).find('td').eq(0).empty().append(divSort);

          // 操作按鈕渲染
          const div = document.createElement('div');
          const root = createRoot(div);
          root.render(
            <>
              <Button
                variant="outline-primary"
                className="mx-1"
                onClick={() => editMode(id)}
              >
                <i className="bi bi-highlighter"></i>
              </Button>
              <Button
                variant="outline-danger"
                className="mx-1"
                onClick={() => deleteMode(id)}
              >
                <i className="bi bi-trash"></i>
              </Button>
            </>
          );
          $(row).find('td').eq(4).empty().append(div);
        },
        drawCallback: function () {
          $('div.dt-info').addClass('text-dark p-0');
          $('div.dt-layout-row').addClass('row mt-2 justify-content-between');

          $('div.dt-layout-start').addClass('col-md-auto me-auto');
          $('div.dt-layout-end').addClass('col-md-auto ms-auto');

          $('div.dt-paging nav').addClass('btn-group');

          setTimeout(() => {
            const buttons = $('button.dt-paging-button');
        
            buttons.each(function () {
              $(this).addClass('btn btn-secondary'); 
              if ($(this).hasClass('current')) {
                $(this).addClass('disabled').prop('disabled', true);
              } 
        
            });

          }, 0);
        },
      });
      $('div.dt-length').hide();

    }
  }, [data]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={12}>
          <h4 className='text-secondary'><b>材質資料</b></h4>
          <p className='text-secondary'>需要先填寫產品的材質內容，才可以進一步的綁定類別資料與產品資料</p>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={12}>
                <Button variant='outline-dark' className="px-4" onClick={() => editMode()}>+&ensp;新增材質資料</Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12}>
                <table ref={tableRef} className="table table-bordered table-hover" style={{ width: '100%' }} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default BackPmaterial;