import { STRAPI, STRAPI_token } from '../../utils/config';
import axios from 'axios';

interface DBResult {
  status: boolean;
  info: any;
  oldInfo?: any;
}

export const DBuploadMulti = async (formData: any): Promise<DBResult> => {
  let err = 0;
  let errTxt = "";

  try {
    const uploadResponse = await axios.post(`${STRAPI}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${STRAPI_token}`,
      },
    });

    if (uploadResponse.status !== 200) {
      throw new Error('檔案上傳有誤');
    }

    return { status: true, info: uploadResponse.data };
  } catch (error: any) {
    console.error('Error:', error.message);
    err++;
    errTxt = error.message;
  }

  return { status: false, info: errTxt };
};