export default function MoreStyle() {
  return (
    <div className="btn btn-default news-stylesBtn">
      {/* 圓形邊框包裹住箭頭 */}
      <span className="d-flex align-items-center justify-content-center rounded-circle border border-secondary" style={{ width: '2rem', height: '2rem' }}>
        {/* 使用 SVG 來顯示箭頭 */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </span>
    </div>
  );
}
