import React, { useState, useEffect, useRef } from 'react';
import { Container, Col, Card, Row, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import { DBuploadDelMulti } from '../../api/back/DBuploadDelMulti';
import { DBupsort } from '../../api/DBupsort';
import { FaArrowAltCircleUp,FaArrowAltCircleDown } from "react-icons/fa";

import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import $ from 'jquery';
import 'datatables.net-responsive-dt';
import 'datatables.net-bs5'; 
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { dataTableLang } from '../../utils/dataTableLang';
import { createRoot } from 'react-dom/client';

DataTable.use(DT);

interface NewsInfo {
  id: number;
  attributes: {
    title_en: string;
    title_zh: string;
    display: boolean;
    likeNum: number;
    sort:number;
    newsgroup:any;
    image: {
      data: Array<{ id: number }>;
    } | null;
  };
}

interface InfoFormat {
  id: number;
  attributes: {
    files: any;
    files_en: any;
    name_en: string;
    name_zh: string;
    display: boolean;
    image?: { data?: { id: number } };
    system?: { data?: { attributes: { name_zh: string } } };
  };
}


const BackNews: React.FC = () => {
  const [data, setData] = useState<NewsInfo[]>([]);
  const [category, setCategoryData] = useState<InfoFormat[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [searchName, setSearchName] = useState<string>('');
  const tableRef = useRef<any>(null);

  const navigate = useNavigate();

  const columns = [
    { title: '類別', data: 'category' },
    { title: '中文名稱', data: 'title_zh' },
    { title: '英文名稱', data: 'title_en' },
    { title: '案讚數', data: 'likeNum' },
    {
      title: '呈現狀態',
      data: 'display',
      render: (display: boolean) =>
        display
          ? '<span >開啟</span>'
          : '<span class="text-danger">關閉</span>',
    },
    { title: '圖片上傳',data: null },
    { title: '操作',data: null },
  ];

  const fetchCategories = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearch('newsgroups', 'populate=*', searchStr, 'sort_ASC');
      setCategoryData(res.info);
    } catch (error) {
      console.error('Fetching categories failed:', error);
    }
  };

  const isChinese = (text: string) => {
    return /[\u4e00-\u9fff]/.test(text); // 判斷是否包含中文字符
  };

  const showData = async () => {
    try {
      const searchStr: any = { nonDel: { type: 'basic', operator: 'eq', text: true } };

      if (selectedCategoryId && selectedCategoryId !== "none") {
        searchStr.newsgroup = { type: 'advance', operator: 'eq', text: selectedCategoryId, other: ["newsgroup", "name_en"] };
      }

      if (selectedStatus) {
        searchStr.display = { type: 'basic', operator: 'eq', text: selectedStatus };
      }

      if (searchName) {
        const trimName = searchName.trim(); 
        if (isChinese(trimName)) {
          searchStr.title_zh = { type: 'basic', operator: 'contains', text: trimName };
        } else {
          searchStr.title_en = { type: 'basic', operator: 'contains', text: trimName };
        }
      }

      const res = await DBsearch('news', 'populate=*', searchStr, 'sort_ASC');
      
      if (res.info.length > 0) {
        setData(res.info);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    }
  };

  const editMode = (id?: number) => {
    if (id) {
      navigate(`/dashboard/BackNewsEdit/`, { state: { id } });
    } else {
      navigate(`/dashboard/BackNewsEdit/`);
    }
  };

  const imgUpload = (id?: number) => {
    if (id) {
      navigate(`/dashboard/BackNewsFileUp/`, { state: { id } });
    }
  };

  const deleteMode = async (id: number, imgData?: number[]) => {
    Swal.fire({
      title: '您確定要刪除這個項目嗎？',
      text: "此操作無法撤銷！",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '是的，刪除它！',
      cancelButtonText: '取消'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (imgData !== undefined) {
          const deleteRes = await DBuploadDelMulti(imgData);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }
        const updateStr = {
          data: {
            nonDel: false
          }
        };
        const res = await DBupdate('news', id, updateStr);
        if (res.info === 200) {
          Swal.fire({
            icon: "success",
            title: "更新成功",
            showConfirmButton: false,
            timer: 1500
          });
          showData();
        }
      }
    });
  };

  /*const moveItem = async (index: number, direction: 'up' | 'down') => {
    const items = Array.from(data);
    const [movedItem] = items.splice(index, 1);
    if (direction === 'up') {
      items.splice(index - 1, 0, movedItem);
    } else {
      items.splice(index + 1, 0, movedItem);
    }

    const storedLists = items.map((item, idx) => ({
      id: item.id,
      sort: idx + 1
    }));

    try {
      await DBupsort('news', storedLists);
      showData();
      Swal.fire({
        icon: "success",
        title: "排序更新成功",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error updating product order:', error);
      Swal.fire({
        icon: "error",
        title: "排序更新失敗",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };*/

  useEffect(() => {
    fetchCategories();
    showData();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      $(tableRef.current).DataTable({
        responsive: true,
        data: data.map((item) => ({
          category: item.attributes.newsgroup?.data?.attributes.name_zh || '無',
          title_zh: item.attributes.title_zh,
          title_en: item.attributes.title_en,
          likeNum: item.attributes.likeNum,
          display: item.attributes.display,
          id: item.id,
          imgData: item.attributes.image?.data?.map((img) => img.id),
        })),
        columns,
        ordering: false,
        searching:false,
        destroy: true,
        pageLength:10,
        language: {
          ...dataTableLang,
          emptyTable: '目前沒有資料',
        },
        headerCallback: function (thead) {
          $(thead).addClass('table-dark');
        },
        createdRow: (row: any, rowData: any) => {
          const { id, imgId } = rowData;
          const div = document.createElement('div');
          const root = createRoot(div);
    
          root.render(
            <>
              <Button
                variant="outline-primary"
                className="mx-1"
                onClick={() => editMode(id)}
              >
                <i className="bi bi-highlighter"></i>
              </Button>
              <Button
                variant="outline-danger"
                className="mx-1"
                onClick={() => deleteMode(id, imgId)}
              >
                <i className="bi bi-trash"></i>
              </Button>
            </>
          );

          const imgUploadDiv = document.createElement('div');
          const imgUploadRoot = createRoot(imgUploadDiv);

          // 動態渲染圖片上傳按鈕
          imgUploadRoot.render(
            <Button
              variant="light"
              className="shadow-sm"
              onClick={() => imgUpload(id)}
            >
              <i className="bi bi-upload"></i>&ensp;圖片上傳
            </Button>
          );
    
          $(row).find('td').eq(5).empty().append(imgUploadDiv);
          $(row).find('td').eq(6).empty().append(div);
        },
        drawCallback: function () {
          $('div.dt-info').addClass('text-dark p-0');
          $('div.dt-layout-row').addClass('row mt-2 justify-content-between');

          $('div.dt-layout-start').addClass('col-md-auto me-auto');
          $('div.dt-layout-end').addClass('col-md-auto ms-auto');

          $('div.dt-paging nav').addClass('btn-group');

          setTimeout(() => {
            const buttons = $('button.dt-paging-button');
        
            buttons.each(function () {
              $(this).addClass('btn btn-secondary'); 
              if ($(this).hasClass('current')) {
                $(this).addClass('disabled').prop('disabled', true);
              } 
        
            });

          }, 0);
        },
      });

      $('div.dt-length').hide();

    }
  }, [data]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={12}>
          <h4 className='text-secondary'><b>文章分享</b></h4>
          <p className='text-secondary'>編輯或填寫分享文章</p>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <Button variant='outline-dark' className="px-4" onClick={() => editMode()}>+&ensp;新增文章</Button>
              </Col>
              <Col xs={6} className="text-end">
                <Button
                  variant="warning"
                  onClick={() => navigate('/dashboard/backNewSort/')}
                >
                  <i className="bi bi-arrows-expand"></i> 選擇文章排序
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={3}>
                <Form.Select
                  value={selectedCategoryId ?? ''}
                  onChange={(e) => setSelectedCategoryId(e.target.value)}
                >
                  <option value="">選擇類別</option>
                  {category.map((cat) => (
                    <option key={cat.id} value={cat.attributes.name_en}>
                      {cat.attributes.name_zh}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={3}>
                <Form.Select
                  value={selectedStatus ?? ''}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="">選擇狀態</option>
                  <option value="true">開啟</option>
                  <option value="false">關閉</option>
                </Form.Select>
              </Col>
              <Col xs={4}>
                <Form.Control
                  type="text"
                  placeholder="輸入產品名稱"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </Col>
              <Col xs={2}>
                <Button variant="secondary" onClick={showData}>
                  <i className="bi bi-search"></i> 搜尋
                </Button>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xs={12}>
                <table ref={tableRef} className="table table-bordered" style={{ width: '100%' }} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default BackNews;