import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Container, Col, Card, Form, Button, Row } from 'react-bootstrap';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import { DBinsert } from '../../api/DBinsert';
import Swal from 'sweetalert2';

interface DataState {
  chName: string;
  enName: string;
  display: boolean;
}

const BackNgroupEdit: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const [data, setData] = useState<DataState>({ chName: '', enName: '', display: true, });

  const { state } = location as any;
  const id = state?.id;
  const dbName = state?.dbName;

  const backUrl = () => {
    return "/dashboard/backNewsGroup";
  }

  const handleBack = () => {
    const urlWeb = backUrl();
    navigate(`${urlWeb}`);
  }


  const showInfo = async (id: number) => {
    try {
      const searchStr = { id: { type: 'basic', operator: 'eq', text: id } };
      const res: any = await DBsearch(dbName, 'populate=*', searchStr);
      const { name_zh, name_en, display} = res.info[0].attributes;

      setData({ chName: name_zh, enName: name_en, display: display });

    } catch (error) {
      console.error('Fetching product name failed:', error);
    }
  }

  const handleSubmit = async () => {
    let err = 0;

    if (data.chName === "" || data.enName === "") {
      err++;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "必填項目未填寫。",
        showConfirmButton: false,
        timer: 1500
      });
    }

    if (err === 0) {
      const dataObject: any = {
        name_zh: data.chName,
        name_en: data.enName,
        display: data.display,
      };

      if (id) {
        // 更新
        const updateStr = {
          data: dataObject,
        };
        const res = await DBupdate(dbName, id, updateStr);
        if (res.info === 200) {
          Swal.fire({
            icon: "success",
            title: "更新成功",
            showConfirmButton: false,
            timer: 1500
          });
          const urlWeb = backUrl();
          navigate(`${urlWeb}`);
        }
      } else {
        // 插入
        const insertStr = {
          data: dataObject,
        };
        const res = await DBinsert(dbName, insertStr);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "新增成功",
            showConfirmButton: false,
            timer: 1500
          });
          const urlWeb = backUrl();
          navigate(`${urlWeb}`);
        }
      }

    }
  };

  useEffect(() => {
    if (id && dbName) {
      showInfo(id);
    }
  }, [id, dbName]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={12}>
          <h4 className='text-secondary'><b>文章類別 / {id ? '編輯頁面' : '新增頁面'}</b></h4>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <button type="button" onClick={() => handleBack()} aria-label="Go back"
                  style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }} >
                  <IoIosArrowDropleftCircle size={30} style={{ color: 'rgba(0,0,0,0.3)' }} />
                </button>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xs={12}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>中文名稱 *</b>
                  </label>
                  <input type='text' className="form-control" placeholder="請輸入中文名稱" value={data.chName} onChange={(e) => setData({ ...data, chName: e.target.value })} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>英文名稱 *</b>
                  </label>
                  <input type='text' className="form-control" placeholder="請輸入英文名稱" value={data.enName} onChange={(e) => setData({ ...data, enName: e.target.value })} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>內容呈現</b>
                  </label>
                  <select className="form-select" value={data.display.toString()} onChange={(e) => setData({ ...data, display: e.target.value === 'true' })}>
                    <option value="true">呈現</option>
                    <option value="false">隱藏</option>
                  </select>
                </div>
              </Col>
              
            </Row>
              
            <Row>
              <Col xs={12} className='text-center mt-2 mb-5'>
                <Button variant="outline-dark" className='px-4' onClick={handleSubmit}>
                  &emsp;送&ensp;出 &emsp;
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

export default BackNgroupEdit;