import React from 'react';
import { Container, Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import { FaAlignJustify, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './NavbarBack.css';

const NavbarBack: React.FC<{ toggleSidebar: () => void }> = ({ toggleSidebar }) => {
  let navigate = useNavigate();

  const handleChkPwd = () => {
    navigate('/dashboard/chPwd');
  };

  const handleLogout = () => {
    Cookies.remove('jwt', { secure: true, sameSite: 'Strict' });
    navigate('/back');
  };
  
  return (
    <>
    <Navbar className="top-navbar shadow-sm p-0">
      <Container fluid>
        <Button onClick={toggleSidebar} className="ms-auto" variant="link">
          <FaAlignJustify style={{ color: 'white' }} />
        </Button>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll></Nav>
          <Nav>
            <Dropdown align="end" >
              <Dropdown.Toggle as={Nav.Link} id="dropdown-user-icon" style={{ marginRight: '10px' }}>
                <FaUser size={20} style={{ color: 'white' }} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-wide drop-custom">
                <Dropdown.Item className='drop-item-custom' onClick={handleChkPwd}>修改密碼</Dropdown.Item>
                <Dropdown.Item className='drop-item-custom' onClick={handleLogout}>登出</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
};

export default NavbarBack;
