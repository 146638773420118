import React, { useEffect, useState } from 'react';
import NavbarFront from '../../components/common/NavbarFront';
import HeaderFront from '../../components/common/HeaderFront';
import { Container, Row, Col  } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FooterFront from '../../components/common/FooterFront';
import { useParams } from 'react-router-dom';
import { DBsearchId } from '../../api/DBsearchId';
import { STRAPI } from '../../utils/config';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import styled from 'styled-components';

const SwiperContainer = styled.div`
  /*carousel*/
  .swiper {
      width: 100%;
      height: 100%;
  }
    
  .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
    
  .swiper-pagination-bullet {
      background-color: white !important;
  }

  .swiper-pagination-bullet-active {
      background-color: #eb5d1e !important; /* Orange color for active state */
  }

`;

const NewDetail: React.FC = () => {
  const { t,i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<any>(null);
  const [groupData, setGroupData] = useState<any>(null);
  const [imageDate, setImageData] = useState<any>(null);

  const showData = async (id:string) => {
    try {
      const searchStr: any = { nonDel: { type: 'basic', operator: 'eq', text: true },display: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearchId('news', 'populate=*', searchStr, 'sort_ASC', Number(id));
      if(res.info.length>0){
        setData(res.info[0].attributes);
        if(res.info[0].attributes.newsgroup.data){
            setGroupData(res.info[0].attributes.newsgroup.data.attributes);
        }
        if(res.info[0].attributes.image.data){
            setImageData(res.info[0].attributes.image.data);
        }
      }
       
    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    }
  };

  useEffect(() => {
    if(id){
        showData(id);
    }
  }, [id]);

  return(
      <>
        <NavbarFront />
        <HeaderFront />
        <Container>
            {data && (
                <>
                    <h1 className="about-title mb-4">
                        <b>{data && i18n.language === 'en' ? data.title_en : data.title_zh}</b>
                    </h1>
                    <span className='text-secondary mt-2'>
                        {t('publishDate')} : {data && data.createdAt.split('T')[0].replace(/-/g, '/')}
                    </span> 
                </>
            )}
           
            <Row className="my-2">
                {groupData && (
                <span className={`news-txt-tag p-1 mx-2 rounded my-2 ${i18n.language === 'en' && 'news-txt-tag-en'}`}>
                    {i18n.language === 'en' ? groupData.name_en : groupData.name_zh}
                </span>
                )}
                {imageDate && imageDate.length>0 && (
                    <Col xs={12}>
                        <Row >
                            <Col xs={6}>
                                <div >
                                <SwiperContainer>
                                    {imageDate.length > 1 ? (
                                    <Swiper
                                        pagination={{ clickable: true }}
                                        autoplay={{ delay: 2500, disableOnInteraction: false }}
                                        modules={[Pagination, Autoplay]}
                                        className="mySwiper"
                                    >
                                        {imageDate.map((item: any, index: number) => {
                                        const url=item.attributes.url;
                                        //const url=item.attributes.formats.medium.url;
                                        const imgUrl = STRAPI + url;
                                        return (
                                            <SwiperSlide key={index}>
                                            <img src={imgUrl} alt="" className='img-fluid' />
                                            </SwiperSlide>
                                        );
                                        })}
                                    </Swiper>
                                    ) : (
                                    <Swiper className="mySwiper">
                                        <SwiperSlide>
                                        {(() => {
                                            const imgUrl = STRAPI + imageDate[0].attributes.url;
                                            return <img src={imgUrl} alt="" className='img-fluid' />;
                                        })()}
                                        </SwiperSlide>
                                    </Swiper>
                                    )}
                                </SwiperContainer>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
            <Row>
                {data && (
                    // 判斷語言，根據選擇的語言來渲染 content_en 或 content_zh
                    (i18n.language === 'en' ? data.content_en : data.content_zh) &&
                    Array.isArray(i18n.language === 'en' ? data.content_en : data.content_zh) &&
                    (i18n.language === 'en' ? data.content_en : data.content_zh).map((item: any, index: number) => (
                        <p key={index}>
                            {item.children && item.children.length > 0 ? item.children[0].text : ''}
                        </p>
                    ))
                )}
            </Row>
        </Container>

        <FooterFront />
      </>
  );
}

export default NewDetail