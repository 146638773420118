import React from 'react';
import { Nav, Accordion } from 'react-bootstrap';
import { FaHome, FaAddressCard , FaBoxOpen, FaImages, FaFileImage, FaRegFilePdf,FaRegFolderOpen } from 'react-icons/fa';
import './SidebarBack.css';
import { Link } from 'react-router-dom';


const SideBarBack: React.FC<{ expanded: Boolean, activeKey: string | null, handleNavClick: (key: string | null) => void }> = ({ expanded, activeKey, handleNavClick }) => {
  return (
    <Nav defaultActiveKey="/home" className="flex-column side-navbar">
        <Nav.Link as={Link} to="/dashboard" className='nav-item nav-item-custom' onClick={() => handleNavClick(null)}>
            <FaHome size={20} /><span className='align-middle px-2'>Dashboard</span>
        </Nav.Link>
        {expanded && (
        <Accordion defaultActiveKey={activeKey}>
            <Nav.Link as={Link} to="/dashboard/backCarousel" className='nav-item nav-item-custom' onClick={() => handleNavClick(null)}>
                <FaImages size={20} /><span className='align-middle px-2'>輪播圖上傳</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/dashboard/backAbout" className='nav-item nav-item-custom' onClick={() => handleNavClick(null)}>
                <FaAddressCard size={20} /><span className='align-middle px-2'>關於我們</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/dashboard/backLogo" className='nav-item nav-item-custom' onClick={() => handleNavClick(null)}>
                <FaFileImage size={20} /><span className='align-middle px-2'>LOGO上傳</span>
            </Nav.Link>
            <Accordion.Item eventKey="1">
            <Accordion.Header onClick={() => handleNavClick('1')}>
                <FaRegFolderOpen  size={20} /><span className='align-middle px-2'>文章管理</span>
            </Accordion.Header>
            <Accordion.Body>
                <Nav.Link as={Link} to="/dashboard/backNewsGroup" className='nav-subItem'>文章類別</Nav.Link>
                <Nav.Link as={Link} to="/dashboard/backNews" className='nav-subItem'>文章分享</Nav.Link>
            </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => handleNavClick('0')}>
                <FaBoxOpen size={20} /><span className='align-middle px-2'>產品管理</span>
            </Accordion.Header>
            <Accordion.Body>
                <Nav.Link as={Link} to="/dashboard/backPmaterial" className='nav-subItem'>材質資料</Nav.Link>
                <Nav.Link as={Link} to="/dashboard/backPtype" className='nav-subItem'>類別資料</Nav.Link>
                <Nav.Link as={Link} to="/dashboard/backPinfo" className='nav-subItem'>產品資料</Nav.Link>
            </Accordion.Body>
            </Accordion.Item>
            <Nav.Link as={Link} to="/dashboard/backPdf" className='nav-item nav-item-custom' onClick={() => handleNavClick(null)}>
                <FaRegFilePdf size={20} /><span className='align-middle px-2'>產品型錄</span>
            </Nav.Link>
            
        </Accordion>
        )}

    </Nav>
  )
}

export default SideBarBack