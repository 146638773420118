import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Dashboard, LoginBack, UserPage } from './pages/back';
import { About, Contact, HomePage, Product,Download, New, NewDetail } from './pages/front';
import { useTranslation } from 'react-i18next';
import './assets/frontPage.css';
import './App.css';

import AOS from 'aos';
import 'aos/dist/aos.css';


// AOS.init();

AOS.init({
  duration: 600,
  easing: 'ease-in-sine',
  offset: 200,
  once: true, 
});

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.title = i18n.language === 'zh' ? '璞木刃刀 | 捷和' : 'Prowood Tool | Jetworld';
  }, [i18n.language]);

  useEffect(() => {
    
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/*" element={<Product />} />
        <Route path="/new" element={<New />} />
        <Route path="/new/:id" element={<NewDetail />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/download" element={<Download />} />
        <Route path="/back" element={<LoginBack />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/userPage" element={<UserPage />} />
      </Routes>
    </Router>
  );
};

export default App;
