import { DBsearchId } from "../api/DBsearchId";

export const NewsTitleTrans = async (id:number, language:string) => {
    try {
        const searchStr: any = { nonDel: { type: 'basic', operator: 'eq', text: true },display: { type: 'basic', operator: 'eq', text: true } };
        const res = await DBsearchId('news', 'populate=*', searchStr, 'search_id_ASC', id);
        if (res.info[0] && res.info[0].attributes) {
            return language === 'en' ? res.info[0].attributes.title_en : res.info[0].attributes.title_zh;
        }
        return null;

    } catch (error) {
        console.error('Fetching category name failed:', error);
        return null;
    }
};