import React,{ useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import NavbarBack from '../../components/common/NavbarBack';
import SideBarBack from '../../components/common/SideBarBack';
import { DashBoardInfo, UserPage, BackAbout, BackPtype,BackPinfo,BackPdf, BackChpwd, LoginBack, BackAboutEdit, BackAboutFileUp, BackPEdit, BackPsort, BackCarousel, BackLogo, BackNewsGroup, BackNewsGroupEdit, BackNews, BackNewsEdit, BackNewsFileUp, BackNewSort, BackPmaterial, BackPmEdit } from './index';
import PrivateRoute from '../../components/common/PrivateRoute';


const Dashboard: React.FC = () => {
  const [expanded, setExpanded] = useState<Boolean>(false);
  const [activeKey, setActiveKey] = useState<string | null>(null);

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };

  const handleNavClick = (key: string | null) => {
    setActiveKey(key); 
  };

  return (
    <>
      <NavbarBack toggleSidebar={toggleSidebar} />

      <div className={`sidebar-container ${expanded ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
        <SideBarBack expanded={expanded} activeKey={activeKey} handleNavClick={handleNavClick}  />
        <Container className="content" fluid>
          <Routes>
            <Route path="/back" element={<LoginBack />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<DashBoardInfo />} />
              <Route path="userPage" element={<UserPage />} />
              <Route path="backCarousel" element={<BackCarousel />} />
              <Route path="backLogo" element={<BackLogo />} />
              <Route path="backnewsGroup" element={<BackNewsGroup />} />
              <Route path="backnewsGroupEdit" element={<BackNewsGroupEdit />} />
              <Route path="backNews" element={<BackNews />} />
              <Route path="backNewsEdit" element={<BackNewsEdit />} />
              <Route path="backNewsFileUp" element={<BackNewsFileUp />} />
              <Route path="backNewSort" element={<BackNewSort />} />
              <Route path="backAbout" element={<BackAbout />} />
              <Route path="backAboutEdit" element={<BackAboutEdit />} />
              <Route path="backPtype" element={<BackPtype />} />
              <Route path="backPinfo" element={<BackPinfo />} />
              <Route path="backPmaterial" element={<BackPmaterial />} />
              <Route path="backPmEdit" element={<BackPmEdit />} />
              <Route path="backPdf" element={<BackPdf />} />
              <Route path="chPwd" element={<BackChpwd />} />
              <Route path="backPEdit" element={<BackPEdit />} />
              <Route path="backPsort" element={<BackPsort />} />
              <Route path="backAboutFileUp" element={<BackAboutFileUp />} />
            </Route>
          </Routes>
        </Container>
      </div>
      
    </>
  );
};

export default Dashboard;
