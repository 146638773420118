import React, { useEffect, useState, useRef } from 'react';
import { Container, Col, Card, Form, Button, Row, Spinner, ButtonGroup } from 'react-bootstrap';
import { DBuploadMulti } from '../../api/back/DBuploadMulti';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import Swal from 'sweetalert2';
import { STRAPI } from '../../utils/config';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { DBuploadDel } from '../../api/back/DBuploadDel';
import { DBinsert } from '../../api/DBinsert';
import { DBdelete } from '../../api/DBdelete';
import { DBupsort } from '../../api/DBupsort';

import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import $ from 'jquery';
import 'datatables.net-responsive-dt';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { dataTableLang } from '../../utils/dataTableLang';
import { createRoot } from 'react-dom/client';

DataTable.use(DT);


const BackLogo: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false); // New state for loading
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [data, setData] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [slides, setSlides] = useState<any>([]);

  const tableRef = useRef<any>(null);

  const columns = [
    { title: '', data: null },
    { title: '(點選圖片可放大查看)', data: 'imageUrl', render: (data: string) => `<img src="${data}" class="img-fluid fixed-size" alt="Logo" />`, className: 'text-center col' },
    { title: '顯示狀態', data: 'display',className:"text-center col-2" },
    { title: '主選單呈現', data: null,className:"text-center col-2" },
    { title: '主選單排序', data: null,className:"text-center col-2" },
    { title: '', data: null,className:"text-center col-1" },
  ];

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));
    }
  };

  const deleteMode = async (id:number,imgId:number) => {
    try {

      const deleteRes = await DBuploadDel(imgId);
      if (!deleteRes.status) {
        throw new Error(deleteRes.message);
      }

      const res = await DBdelete('logos', id);
      if(res.status===200){
        Swal.fire({
            icon: "success",
            title: "success",
            text: "刪除成功",
            showConfirmButton: false,
            timer: 1500
        });
        
      }
      showInfo();

      
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "刪除失敗",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  const handleOpenLightbox = (index:number) => {
    const slides = data.map((info: any) => ({ src: `${STRAPI}${info.attributes.image.data.attributes.url}` }));
    setSlides(slides);
    setOpen(true);
  };

  const showInfo = async () => {
    try {
      const res: any = await DBsearch('logos', 'populate=*',null,'sort_ASC');
      if(res.info.length>0){
        setData(res.info);
      }else{
        setData([]);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
    }
  }

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setUploadStatus('No files selected');
      return;
    }

    setIsUploading(true); // Set loading state to true

    const renamedFiles = selectedFiles.map(file => {
      const date = new Date();
      const formattedDate = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
      const newFileName = `logos_${formattedDate}_${file.name}`;
      return new File([file], newFileName, { type: file.type });
    });

    const formData = new FormData();
    renamedFiles.forEach(file => {
      formData.append('files', file);
    });
    formData.append('path', 'logos');

    try {
      const res = await DBuploadMulti(formData);
      if (res.status === true) {
        const newImgData = res.info.map((file: { id: number }) => file.id);

        // 使用 Promise.all 来等待所有插入操作完成
        await Promise.all(newImgData.map(async (imgId: number) => {
          try {
            await DBinsert('logos', { data: { image: [imgId] } });
          } catch (insertError) {
            console.error(`Failed to insert image with ID: ${imgId}`, insertError);
          }
        }));

        Swal.fire({
          icon: 'success',
          title: 'success',
          text: '上傳成功',
          showConfirmButton: false,
          timer: 1500,
        });

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        // 确保所有插入操作完成后再执行 showInfo
        showInfo();
        setSelectedFiles([]);
      }
    } catch (error) {
      console.error('Error:', error);
      setUploadStatus('上傳有誤');
    } finally {
      setIsUploading(false); // Set loading state to false
    }
  };

  const toggleDisplay = async (index: number, display: boolean) => {
    const newData = [...data]; 
    newData[index].attributes.display = display; 
    try {
      // 更新到後端資料庫
      const res = await DBupdate('logos', newData[index].id, { data:{display: display} });
      if(res.info===200){
        setData(newData);
        Swal.fire({
            icon: "success",
            title: "狀態更新成功",
            showConfirmButton: false,
            timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error updating display status:', error);
      Swal.fire({
        icon: "error",
        title: "狀態更新失敗",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const toggleHeader = async (index: number, headerShow: boolean) => {
    const newData = [...data];
    newData[index].attributes.headerShow = headerShow;
  
    try {
      // 更新到後端資料庫
      const res=await DBupdate('logos', newData[index].id, { data:{headerShow: headerShow} });
      if(res.info===200){
        setData(newData);
        Swal.fire({
            icon: "success",
            title: "主選單呈現更新成功",
            showConfirmButton: false,
            timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error updating display status:', error);
      Swal.fire({
        icon: "error",
        title: "主選單呈現更新失敗",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleHeaderChange = async (index: number, sortValue: string) => {
    const newData = [...data];
    newData[index].attributes.sort = Number(sortValue); // 更新選擇的排序值
    
    try {
      // 更新排序值到後端
      await DBupdate('logos', newData[index].id, { data:{headerNum: newData[index].attributes.sort} });
      setData(newData);
      Swal.fire({
        icon: "success",
        title: "排序更新成功",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error('Error updating sort:', error);
      Swal.fire({
        icon: "error",
        title: "排序更新失敗",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  
  const moveItem = async (index: number, direction: 'up' | 'down') => {
    const items = Array.from(data);
    const [movedItem] = items.splice(index, 1);
    if (direction === 'up') {
      items.splice(index - 1, 0, movedItem);
    } else {
      items.splice(index + 1, 0, movedItem);
    }

    const storedLists = items.map((item:any, idx:number) => ({
      id: item.id,
      sort: idx + 1
    }));

    try {
      const response = await DBupsort('logos', storedLists);
      if(response.info===200){
        showInfo();
        Swal.fire({
          icon: "success",
          title: "排序更新成功",
          showConfirmButton: false,
          timer: 1500
        });
      }else{
        Swal.fire({
          icon: "error",
          title: "排序更新失敗",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      console.error('Error updating product order:', error);
      Swal.fire({
        icon: "error",
        title: "排序更新失敗",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };


  useEffect(() => {
    showInfo();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      $(tableRef.current).DataTable({
        responsive: true,
        data: data.map((item:any, index:number) => {
          const imageData = item.attributes.image?.data;
          const imageUrl = imageData ? `${STRAPI}${imageData.attributes.url}` : ''; 
  
          return {
            id: item.id,
            imgId: imageData?.id || null,
            imageUrl,
            display: item.attributes.display, // 顯示狀態
            headerShow: item.attributes.headerShow, // 主選單呈現
            headerNum: item.attributes.headerNum, // 主選單排序
            sort: item.attributes.sort,
            sortIndex: index,
          };
        }),
        columns,
        columnDefs: [
          { targets: [0, 3, 4], className: 'text-center' },
        ],
        ordering: false,
        searching: false,
        destroy: true,
        pageLength: 3,
        language: {
          ...dataTableLang,
          emptyTable: '目前尚未有圖片上傳',
        },
        headerCallback: (thead) => {
          $(thead).addClass('table-dark text-center');
        },
        createdRow: (row: any, rowData: any, index: number) => {
          const { id, imgId, display, headerShow, sort, sortIndex, headerNum } = rowData;

          const imgCell = $(row).find('td').eq(1);
          imgCell.on('click', () => handleOpenLightbox(index));

          // Sorting buttons rendering
          const divSort = document.createElement('div');
          const rootSort = createRoot(divSort);
          rootSort.render(
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="outline-light"
                disabled={sortIndex === 0}
                onClick={() => moveItem(sortIndex, 'up')}
              >
                <i
                  className={`bi bi-arrow-up-circle-fill ${
                    sortIndex === 0 ? 'text-secondary' : 'text-primary'
                  }`}
                  style={{ fontSize: '1.25rem' }}
                ></i>
              </Button>
              &ensp;
              <Button
                variant="outline-light"
                disabled={sortIndex === data.length - 1}
                onClick={() => moveItem(sortIndex, 'down')}
              >
                <i
                  className={`bi bi-arrow-down-circle-fill ${
                    sortIndex === data.length - 1 ? 'text-secondary' : 'text-danger'
                  }`}
                  style={{ fontSize: '1.25rem' }}
                ></i>
              </Button>
            </div>
          );
          $(row).find('td').eq(0).empty().append(divSort);
  
          // 顯示狀態按鈕渲染
          const divDisplay = document.createElement('div');
          const rootDisplay = createRoot(divDisplay);
          rootDisplay.render(
            <ButtonGroup>
              <Button variant="primary" onClick={() => toggleDisplay(index, true)} disabled={display}>顯示</Button>
              <Button variant="primary" onClick={() => toggleDisplay(index, false)} disabled={!display}>隱藏</Button>
            </ButtonGroup>
          );
          $(row).find('td').eq(2).empty().append(divDisplay);
  
          // 主選單呈現按鈕渲染
          const divHeaderShow = document.createElement('div');
          const rootHeaderShow = createRoot(divHeaderShow);
          rootHeaderShow.render(
            <ButtonGroup>
              <Button variant="primary" onClick={() => toggleHeader(index, true)} disabled={headerShow}>是</Button>
              <Button variant="primary" onClick={() => toggleHeader(index, false)} disabled={!headerShow}>否</Button>
            </ButtonGroup>
          );
          $(row).find('td').eq(3).empty().append(divHeaderShow);
  
          // 主選單排序渲染
          const divHeaderSort = document.createElement('div');
          const rootHeaderSort = createRoot(divHeaderSort);
          rootHeaderSort.render(
            <Form.Select value={headerShow ? headerNum : 100} disabled={!headerShow} onChange={(e) => handleHeaderChange(index, e.target.value)}>
              <option value={100}>===選擇排序===</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </Form.Select>
          );
          $(row).find('td').eq(4).empty().append(divHeaderSort);
  
          // 刪除按鈕渲染
          const divDelete = document.createElement('div');
          const rootDelete = createRoot(divDelete);
          rootDelete.render(
            <Button variant="outline-danger" onClick={() => deleteMode(id, imgId)}>
              <i className="bi bi-trash"></i>
            </Button>
          );
          $(row).find('td').eq(5).empty().append(divDelete);
        },
        drawCallback: () => {
          $('div.dt-info').addClass('text-dark p-0');
          $('div.dt-layout-row').addClass('row mt-2 justify-content-between');
          $('div.dt-layout-start').addClass('col-md-auto me-auto');
          $('div.dt-layout-end').addClass('col-md-auto ms-auto');
          $('div.dt-paging nav').addClass('btn-group');
  
          setTimeout(() => {
            const buttons = $('button.dt-paging-button');
            buttons.each(function () {
              $(this).addClass('btn btn-secondary');
              if ($(this).hasClass('current')) {
                $(this).addClass('disabled').prop('disabled', true);
              }
            });
          }, 0);
        },
      });
  
      $('div.dt-length').hide();
    }
  }, [data]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={2}>
          <h4 className='text-secondary'><b>Logo上傳</b></h4>
          <p className='text-secondary'>Logo上傳的"顯示狀態"是針對首頁內容頁區塊，與"主選單呈現"分開。</p>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row className='mt-3'>
              <Col md={10}>
                <Form.Group controlId="formFiles" >
                  <Form.Label className='text-dark'><b>選擇上傳圖片</b></Form.Label>
                  <Form.Control type="file" ref={fileInputRef} multiple onChange={handleFilesChange} />
                </Form.Group>
                <p className='text-secondary'>建議上傳檔案為.jpg 或 .png。</p>
                <p className='text-danger'>{uploadStatus}</p>
              </Col>
              <Col md={2} className='text-center mt-4 pt-2'>
                <Form.Group controlId="formFiles" >
                  <Form.Label className='text-dark'></Form.Label>
                  <Button 
                    variant="outline-dark" 
                    className='w-100 p-1' 
                    onClick={handleUpload}
                    disabled={isUploading} // Disable button while uploading
                  >
                    {isUploading ? <Spinner as="span" animation="border" size="sm" /> : '送出'}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <Row className="text-dark mt-3">
              <Col xs={12}>
                <table ref={tableRef} className="table table-bordered table-hover" style={{ width: '100%' }} />
              </Col>
            </Row>
            {
              data.length>0 && <Lightbox open={open} close={() => setOpen(false)} slides={slides} />
            }
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default BackLogo;