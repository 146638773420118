import React from 'react'
import NavbarFront from '../../components/common/NavbarFront';
import HeaderFront from '../../components/common/HeaderFront';
import { FaWhatsapp  } from "react-icons/fa";
import { AiFillWechat  } from "react-icons/ai";
import { FaLine } from "react-icons/fa6";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import CopyableText from '../../utils/CopyableText';
import { useTranslation } from 'react-i18next';
import FooterFront from '../../components/common/FooterFront';

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const form = document.getElementById('contactForm') as HTMLFormElement | null;
    if (form) {
      form.reset();
    } else {
      console.error('Form not found');
    }
  };

  const handleSubmit = (event:any) => {
    event.preventDefault(); 

    const name = event.target.formGroupName.value;
    const email = event.target.formGroupEmail.value;
    const subject = event.target.formGroupSubject.value;
    const message = event.target.formGroupMessage.value;
  
    const mailtoLink = `mailto:jetworld@hibox.hinet.net?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent("Name: " + name + "\nEmail: " + email + "\n\n" + message)}`;
  
    window.location.href = mailtoLink;
  };
  return(
      <>
        <NavbarFront />
        <HeaderFront />
        <Container className='pt-5 pb-5 main-content'>
          <Row>
            <Col md={{offset:1,span:5}} className="p-3 bg-dark-orange text-white shadow custom-gap">
              <Row className='bg-light-gray m-3'>
                <Col className="p-4">
                  <h4><b>{t('Address')}</b></h4>
                  <CopyableText text={t('AddressInfo')} tooltipText={`${t('copyText')}${t('Address')}`}  />
                </Col>
              </Row>
              <Row className='bg-light-gray m-3'>
                <Col className="p-4">
                  <h4>{t('Phone')}</h4>
                  <CopyableText text="+886-2-2392-5866" tooltipText={`${t('copyText')}${t('Phone')}`} />
                </Col>
              </Row>
              <Row className='bg-light-gray m-3'>
                <Col className="p-4">
                  <h4>{t('Fax')}</h4>
                  <CopyableText text="+886-2-2392-5881" tooltipText={`${t('copyText')}${t('Fax')}`} />
                </Col>
              </Row>
              <Row className='bg-light-gray m-3'>
                <Col className="p-4">
                  <h4>{t('Email')}</h4>
                  <CopyableText text="jetworld@hibox.hinet.net" tooltipText={`${t('copyText')}${t('Email')}`} />
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-2">
                <CopyableText text="rogerhung1988" tooltipText={`${t('copyText')}Line ID`}>
                  <button className='btn btn-outline-light rounded'>
                    <FaLine size={30} />
                  </button>
                </CopyableText>&emsp;
                <CopyableText text="rogerhung1988" tooltipText={`${t('copyText')}WeChat ID`}>
                  <button className='btn btn-outline-light'>
                    <AiFillWechat size={30} />
                  </button>
                </CopyableText>&emsp;
                <CopyableText text="+86 18820770402" tooltipText={`${t('copyText')}WhatsApp`}>
                  <button className='btn btn-outline-light'>
                    <FaWhatsapp size={30} />
                  </button>
                </CopyableText>
              </div>
            </Col>
            <Col md={5} className="p-3 shadow pt-4" >
              <h4 className='pt-3 pb-3' style={{color:'orange'}}>{t('Contact Title')}</h4>
              <Form id="contactForm"  className="gy-4" onSubmit={handleSubmit}>
                <Form.Group controlId="formGroupName" className='mb-3'>
                  <Form.Label className="text-secondary"><b>{t('Your Name')}</b></Form.Label>
                  <Form.Control type="text" />
                </Form.Group>

                <Form.Group controlId="formGroupEmail" className='mb-3'>
                  <Form.Label className="text-secondary"><b>{t('Your Email')}</b></Form.Label>
                  <Form.Control type="email" />
                </Form.Group>

                <Form.Group controlId="formGroupSubject" className='mb-3'>
                  <Form.Label className="text-secondary"><b>{t('Subject')}</b></Form.Label>
                  <Form.Control type="text" />
                </Form.Group>

                <Form.Group controlId="formGroupMessage" className='mb-3'>
                  <Form.Label className="text-secondary"><b>{t('Message')}</b></Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>

                <div className="text-center pt-3">
                  <Button variant="outline-secondary" className='px-5' onClick={handleReset}>{t('clear')}</Button>
                  &emsp;
                  <Button variant='dark' className='px-5' type="submit">{t('send')}</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
        <Container className='pt-2' fluid>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.793467905052!2d121.52644807595645!3d25.041082138005294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a97bc8e8be4b%3A0x32af293acea915bc!2zMTAw5Y-w5YyX5biC5Lit5q2j5Y2A5r-f5Y2X6Lev5LqM5q61MzHkuYs0MOiZnzEx5qiT!5e0!3m2!1szh-TW!2stw!4v1717568428014!5m2!1szh-TW!2stw"
            width="100%" height="450" style={{ border: 0 }}loading="lazy">
          </iframe>
        </Container>
        <FooterFront />
      </>
  );
}

export default Contact