import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Container, Col, Card, Form, Button, Row, Table, Pagination } from 'react-bootstrap';
import { DBsearch } from '../../api/DBsearch';
import Swal from 'sweetalert2';
import { JSX } from 'react/jsx-runtime';
import { DBupdate } from '../../api/DBupdate';
import { DBupsort } from '../../api/DBupsort';

interface InfoFormat {
  id: number;
  attributes: {
    name_en: string;
    name_zh: string;
    title_en: string;
    title_zh: string;
    display: boolean;
    sort: number;
    newsgroup: {
      data: {
        [x: string]: any;
        id: number;
      };
    };
    image: {
      data: { id: number };
    } | null;
  };
}

const BackNewSort: React.FC = () => {
  const navigate = useNavigate();
  const [category, setCategoryData] = useState<InfoFormat[]>([]);
  const [product, setProductData] = useState<InfoFormat[]>([]);
  const [topProducts, setTopProducts] = useState<InfoFormat[]>([]);
  const [selectedCategoryName, setselectedCategoryName] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(8);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleBack = () => {
    navigate(`/dashboard/backNews`);
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setselectedCategoryName(event.target.value);
  };

  const handleSearch = () => {
    if (selectedCategoryName !== null && selectedCategoryName !== 'none') {
      showNews(selectedCategoryName);
    }
  };

  const showCategory = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearch('newsgroups', 'populate=*', searchStr, 'search_id_ASC');
      if (res.info.length > 0) {
        setCategoryData(res.info);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    }
  };

  const showSelectedNews = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true }, sort: { type: 'basic', operator: 'lte', text: 6 } };
      const res = await DBsearch('news', 'populate=*', searchStr);
      if (res.info.length > 0) {
        setTopProducts(res.info);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
    }
  };

  const showNews = async (name: string) => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true }, name_en: { type: 'advance', operator: 'eq', text: name, other: ["newsgroup", "name_en"] } };
      const res = await DBsearch('news', 'populate=*', searchStr);
      if (res.info.length > 0) {
        const otherProducts = res.info.filter(item => item.attributes.sort === 100);
        setProductData(otherProducts);
      } else {
        setProductData([]);
      }
    } catch (error) {
      console.error('Fetching product name failed:', error);
    }
  };

  /*新增文章為置定項目*/
  const handleAddTopProduct = async(prod: InfoFormat) => {
    if (topProducts.length < 5 && !topProducts.some(p => p.id === prod.id)) {
      const sortNum = Number(topProducts.length)+1;
      const updateStr = {
        data: {
          sort: sortNum
        }
      };
      const res = await DBupdate('news', prod.id, updateStr);
      if (res.info === 200) {
        Swal.fire({
          icon: "success",
          title: "已新增文章為置頂",
          showConfirmButton: false,
          timer: 1500
        });

        setTopProducts([...topProducts, prod].sort((a, b) => a.attributes.sort - b.attributes.sort));
        const updatedProducts = product.filter(p => p.id !== prod.id);
        setProductData(updatedProducts);

        const filteredProducts = updatedProducts.filter(prod =>
          prod.attributes.title_zh.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
        if (currentPage > totalPages) {
          setCurrentPage(1); // 當前頁無產品時，跳回第一頁
        }
      }
    } else {
      Swal.fire('已達到五個文章的限制或文章已經在置頂列表中', '', 'warning');
    }
  };

  /*移除文章為置定項目*/
  const handleRemoveTopProduct = async (sysName: string | null,productId: number) => {
    const productToRemove = topProducts.find(p => p.id === productId);
    if (productToRemove) {
      const updateStr = {
        data: {
          sort: 100
        }
      };
      const res = await DBupdate('news', productId, updateStr);
      if (res.info === 200) {
        Swal.fire({
          icon: "success",
          title: "移除該文章為置頂",
          showConfirmButton: false,
          timer: 1500
        });
        setTopProducts(topProducts.filter(p => p.id !== productId));
        if (sysName === selectedCategoryName) {
          setProductData([...product, productToRemove]);
        }
      }
    }
  };

  const moveItem = async (data: InfoFormat[], direction: 'up' | 'down', index: number) => {
    const items = [...data];
    const [movedItem] = items.splice(index, 1);
    if (direction === 'up') {
      items.splice(index - 1, 0, movedItem);
    } else {
      items.splice(index + 1, 0, movedItem);
    }

    const updatedTopProducts = items.map((item, idx) => ({
      ...item,
      attributes: {
        ...item.attributes,
        sort: idx + 1
      }
    }));

    try {
      await DBupsort('news', updatedTopProducts.map(item => ({
        id: item.id,
        sort: item.attributes.sort
      })));
      setTopProducts(updatedTopProducts);
      Swal.fire({
        icon: "success",
        title: "排序更新成功",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error updating product order:', error);
      Swal.fire({
        icon: "error",
        title: "排序更新失敗",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  const handleMoveUp = (index: number) => {
    if (index > 0) {
      moveItem(topProducts, 'up', index);
    }
  };

  const handleMoveDown = (index: number) => {
    if (index < topProducts.length - 1) {
      moveItem(topProducts, 'down', index);
    }
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };
  
  /*頁碼start*/
  const filteredProducts = product.filter(prod =>
    prod.attributes.title_zh.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentNews = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const getPageItems = () => {
    let items: JSX.Element[] = [];
    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

    if (totalPages <= 1) return items;

    items.push(
      <Pagination.First key="first" onClick={() => paginate(1)} />
    );
    items.push(
      <Pagination.Prev key="prev" onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)} />
    );

    if (currentPage > 3) {
      items.push(<Pagination.Item key={1} onClick={() => paginate(1)}>{1}</Pagination.Item>);
      items.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
          {number}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages - 2) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      items.push(
        <Pagination.Item key={totalPages} onClick={() => paginate(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    items.push(
      <Pagination.Next key="next" onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)} />
    );
    items.push(
      <Pagination.Last key="last" onClick={() => paginate(totalPages)} />
    );

    return items;
  };
  /*頁碼end*/

  useEffect(() => {
    showCategory();
    showSelectedNews();
  }, []);

  useEffect(() => {
    if (selectedCategoryName) {
      showNews(selectedCategoryName);
    }
  }, [currentPage]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={12}>
          <h4 className='text-secondary'><b>文章分享 / 文章排序</b></h4>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <button type="button" onClick={handleBack} aria-label="Go back"
                  style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }} >
                  <IoIosArrowDropleftCircle size={30} style={{ color: 'rgba(0,0,0,0.3)' }} />
                </button>
              </Col>
            </Row>
            <Row className='mt-4 mb-4'>
              <b className='text-dark' style={{fontSize:'1rem'}}>1.請先選擇文章類別，再進行置頂排序</b>
              <Col lg={10} className='pt-2'>
                <Form.Select value={selectedCategoryName ?? ''} onChange={handleCategoryChange}>
                  <option value="none">選擇類別</option>
                  {category.map(cat => (
                    <option key={cat.id} value={cat.attributes.name_en}>{cat.attributes.name_zh}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col lg={2} className='pt-2'>
                <Button variant='outline-primary' className='w-100' onClick={handleSearch}>
                  搜尋
                </Button>
              </Col>
            </Row>
            <Row >
              <Col></Col>
              <Col></Col>
            </Row>
            <Row>
              <Col className='m-1 p-2 '>
                <p className='text-dark' style={{fontSize:'1rem'}}><b>2.未選擇置頂文章：</b></p>
                <Form.Control type="text" placeholder="快速搜尋文章名稱" value={searchTerm}
                    onChange={handleSearchInputChange} className="mb-2" />

                <div className='bg-white border rounded p-4'>
                  {currentNews.map(prod => (
                    <Button key={prod.id} variant="secondary" className='w-100 mt-2 pt-2' onClick={() => handleAddTopProduct(prod)}>
                      {prod.attributes.title_zh}
                    </Button>
                  ))}
                </div>

                <Pagination className="pt-2 justify-content-center">
                  {getPageItems()}
                </Pagination>
              </Col>
              <Col className='m-1 p-2'>
                <p className='text-dark' style={{fontSize:'1rem'}}><b>3.最多選5個文章為置頂，其他文章會依照建立時間排序。</b></p>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th></th>
                      <th>文章名稱</th>
                      <th>排序</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {topProducts.map((product, index) => (
                      <tr key={product.id}>
                        <td>
                          <Button variant='outline-light'  disabled={index === 0}  onClick={() => handleMoveUp(index)}>
                            {
                              index==0 ? (
                                <i className="bi bi-arrow-up-circle-fill" style={{color:'#BBBBBB',fontSize:'1.25rem'}}></i>
                              ):(
                                <i className="bi bi-arrow-up-circle-fill" style={{color:'#5B91C4',fontSize:'1.25rem'}}></i>
                              )
                            }
                          </Button>
                          <Button variant='outline-light'  disabled={index === topProducts.length - 1}  onClick={() => handleMoveDown(index)}>
                            {
                              index === topProducts.length - 1 ? (
                                <i className="bi bi-arrow-down-circle-fill" style={{color:'#BBBBBB',fontSize:'1.25rem'}}></i>
                              ):(
                                <i className="bi bi-arrow-down-circle-fill" style={{color:'#FF5733',fontSize:'1.25rem'}}></i>
                              )
                            }
                          </Button>
                          {/* <Button variant="link" onClick={() => handleMoveUp(index)} disabled={index === 0}>上移</Button>
                          <Button variant="link" onClick={() => handleMoveDown(index)} disabled={index === topProducts.length - 1}>下移</Button> */}
                        </td>
                        <td>{product.attributes.title_zh}</td>
                        <td>{index+1}</td>
                        <td>
                          <Button variant="danger" onClick={() => handleRemoveTopProduct(product.attributes.newsgroup?.data?.attributes.name_en,product.id)}><i className="bi bi-trash"></i></Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default BackNewSort;