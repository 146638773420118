import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import './HeaderFront.css'; 
import { ProductCateTrans,ProductNameTrans } from '../../apiTranslate/productTrans';
import { NewsTitleTrans } from '../../apiTranslate/newTrans';
import styles from './HeaderFront.module.css'; 

interface BreadcrumbItem {
    path: string;
    label: string;
    isProduct?: boolean; 
}

const HeaderFront: React.FC = () => {
    const { t,i18n } = useTranslation(); 
    const location = useLocation(); 
    const [newTitle, setNewTitle] = useState<string>('');
    const [materialName, setMaterialName] = useState<string>('');
    const [categoryName, setCategoryName] = useState<string>('');
    const [productName, setProductName] = useState<string>('');

    //title
    const pageTitle: { [key: string]: string } = {
        '/about': t('About Us'),
        '/new': t('News'),
        '/product': t('Product'),
        '/contact': t('Contact Us'),
        '/download': t('Download'),
        '/testPage':'產品介紹'
    };

    const isProductPage = location.pathname.startsWith('/product');
    const isNewsPage = location.pathname.startsWith('/new');
    const title = isProductPage ? pageTitle['/product'] : pageTitle[location.pathname] || t('Home');
    //const title = isProductPage ? pageTitle['/product'] : isNewsPage ? pageTitle['/new'] : pageTitle[location.pathname] || t('Home');
    const pageIdentifier = location.pathname.split('/')[1];
    const seriesName = location.pathname.split('/')[2]; 
    const subName = location.pathname.split('/')[3];


    let breadcrumbItems: BreadcrumbItem[] = [
        { path: "/", label: t('Home') },
    ];

    if (isProductPage && seriesName) {
        //處理產品的麵包屑
        breadcrumbItems.push({ path: "/product", label: t('Product') });
        if(seriesName){
            breadcrumbItems.push({ path: `/product/${seriesName}`, label: `${materialName}-${categoryName}` || seriesName });
            if (subName) {
                breadcrumbItems.push({ path: `/product/${seriesName}/${subName}`, label: productName });
            }
        }
    }else if (isNewsPage && seriesName) { // 處理新聞頁面的麵包屑
        breadcrumbItems.push({ path: "/new", label: t('News') });
        if (subName) { // 第三層存在時
            breadcrumbItems.push({ path: `/new/${seriesName}/${subName}`, label: newTitle || subName });
        } else {
            breadcrumbItems.push({ path: `/new/${seriesName}`, label: newTitle || seriesName });
        }
    }else if (pageTitle[`/${pageIdentifier}`]) {
        breadcrumbItems.push(
            { path: location.pathname, label: pageTitle[`/${pageIdentifier}`] }
        );
    }

    const showMaterial = async()=>{//產品類別
        try {
            const nameEn = seriesName.replace(/_/g, ' ');
            const translatedName = await ProductCateTrans('material',nameEn, i18n.language);
            setMaterialName(translatedName);
        } catch (error) {
            console.error("Error fetching category name:", error);
        }
    }

    const showCategory = async()=>{//產品類別
        try {
            const nameEn = seriesName.replace(/_/g, ' ');
            const translatedName = await ProductCateTrans('category',nameEn, i18n.language);
            setCategoryName(translatedName);
        } catch (error) {
            console.error("Error fetching category name:", error);
        }
    }

    const showProduct = async()=>{//產品名稱
        const nameEnTmp = subName.replace(/__/g, '/');
        const nameEn = nameEnTmp.replace(/_/g, ' ');
        try {
            const translatedProductName = await ProductNameTrans(nameEn, i18n.language);
            setProductName(translatedProductName);
        } catch (error) {
            console.error("Error fetching product name:", error);
        }
    }

    const showNewsTitle = async(id:string)=>{
        try {
            const translatedTitleName = await NewsTitleTrans(Number(id), i18n.language);
            setNewTitle(translatedTitleName);
        } catch (error) {
            console.error("Error fetching product name:", error);
        }
    }

    useEffect(() => {
       if(pageIdentifier==='product' && seriesName){
            showMaterial();
            showCategory();
            if(subName){
                showProduct();
            }
        }
        if(pageIdentifier==='new'){
            showNewsTitle(seriesName);
        }
    }, [location.pathname, i18n.language]);

    return (
    <>
        {/* <div className={`header-front`}>
            <div className="header-background " style={{ backgroundImage: 'url(/images/header4.jpg)' }}></div>
            <Container className="text-center py-5">
                <h1 className="text-white display-2 mt-md-3 mt-sm-3 mt-xs-2" data-aos="zoom-in" data-aos-duration="600" data-aos-easing="ease-in-sine" data-aos-offset="200"><b>{title}</b></h1>
                <Breadcrumb className="breadcrumb-custom" data-aos="zoom-in" data-aos-delay="300" data-aos-duration="600" data-aos-easing="ease-in-sine" data-aos-offset="200">
                    {breadcrumbItems.map((val, index) => (
                        <LinkContainer to={val.path} key={index}>
                            <Breadcrumb.Item className='p-0' active={index === breadcrumbItems.length - 1}>
                                <span className={`${val.label === t('Product') ? 'bold' : ''}`} style={{fontSize:'1.1rem'}}>
                                    {val.label}
                                </span>
                            </Breadcrumb.Item>
                        </LinkContainer>
                    ))}
                </Breadcrumb>
            </Container>
        </div> */}
        <div className={`${styles.headerFront}`}>
            <div className={`${styles.headerBackground}`} style={{ backgroundImage: 'url(/images/header4.jpg)' }}></div>
            <Container className="text-center py-5">
                <h1 className="text-white display-2 mt-md-3 mt-sm-3 mt-xs-2" data-aos="zoom-in" data-aos-duration="600" data-aos-easing="ease-in-sine" data-aos-offset="200"><b>{title}</b></h1>
                <Breadcrumb className={`${styles.breadcrumbCustom}`} data-aos="zoom-in" data-aos-delay="300" data-aos-duration="600" data-aos-easing="ease-in-sine" data-aos-offset="200">
                    {breadcrumbItems.map((val, index) => (
                        <LinkContainer to={val.path} key={index}>
                            <Breadcrumb.Item className={`${styles.breadcrumbItemCustom} p-0`} active={index === breadcrumbItems.length - 1}>
                                <span className={`${val.label === t('Product') ? 'bold' : ''}`} style={{fontSize:'1.1rem'}}>
                                    {val.label}
                                </span>
                            </Breadcrumb.Item>
                        </LinkContainer>
                    ))}
                </Breadcrumb>
            </Container>
        </div>
    </>
    
    );
}

export default HeaderFront;