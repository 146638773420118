
interface TransformedType {
    [key: string]: { $contains?: string, $gte?: string, $lte?: string, $eq?:string };
}

export const transformKeys = (searchObj: { [x: string]: any; }, DBname: string) => {
    const transformed: TransformedType = {};

    const getColumnTimeName = () => {
        //若有時間的紀錄，需要在這這修改tableName & columnName，這邊是寫死的。
        if(DBname === 'orders') {
            return 'orderTime';
        } else if(DBname === 'invoice-apis') {
            return 'InvoiceTime';
        }
        return 'errTime';
    };

    for (const key in searchObj) {
        const { type, operator, text, other } = searchObj[key];
        
        if (text && text !== '') {
            if (type === 'basic' && key !== 'type') {
                //一階
                if (key === 'startTime' || key === 'endTime') {
                    const columnKeyName = getColumnTimeName();
                    if (!columnKeyName) {
                        console.error('Error: ' + columnKeyName);
                    }

                    const timeStr = text + (key === 'startTime' ? "T00:00:00Z" : "T23:59:59Z");
                    transformed[columnKeyName] = {
                        ...transformed[columnKeyName],
                        [key === 'startTime' ? '$gte' : '$lte']: timeStr
                    };
                } else {
                    transformed[key] = { [`$${operator}`]: text };
                }
            }else if(type === 'advance' && other){//有其他關聯資料表
                //其他往下找
                let current = transformed;
                other.forEach((item:any, index:number) => {
                    if (index === other.length - 1) {
                        current[item] = { [`$${operator}`]: text };
                    } else {
                        current[item] = current[item] || {};
                        current = current[item] as TransformedType;
                    }
                });
            }
        }
    }

    return { transformed };
};