import { STRAPI, STRAPI_token } from '../../utils/config';
import axios from 'axios';

interface DBResult {
    status: boolean;
    info: any;
    oldInfo?: any;
}

export const DBupload = async (formDataZH: FormData | null, formDataEN: FormData | null, DBname: string): Promise<DBResult> => {   
    let err = 0;
    let errTxt = "";
    let oldInfo;

    const uploadFile = async (formData: FormData) => {
        try {
            const uploadResponse = await axios.post(`${STRAPI}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${STRAPI_token}`,
                },
            });

            if (uploadResponse.status !== 200) {
                throw new Error('檔案上傳有誤');
            } else {
                const uploadData = uploadResponse.data;
                return uploadData[0].id;
            }
        } catch (error) {
            throw new Error('檔案上傳有誤');
        }
    };

    try {
        const url = `${STRAPI}/api/${DBname}`;
        oldInfo = await axios.get(`${url}?populate=*`, {
            headers: {
                Authorization: `Bearer ${STRAPI_token}`
            }
        });

        const tasks = [];
        const updateData: any = {};

        if (DBname === 'pdf-download') {
            if (oldInfo.data.data.attributes.pdfFile.data && formDataZH) {
                const oldIdZH = oldInfo.data.data.attributes.pdfFile.data.id;
                const delInfoZH = await axios.delete(`${STRAPI}/api/upload/files/${oldIdZH}`, {
                    headers: {
                        Authorization: `Bearer ${STRAPI_token}`
                    },
                });

                if (delInfoZH.status !== 200) {
                    throw new Error('中文檔案刪除失敗');
                }

                tasks.push(uploadFile(formDataZH).then(fileId => {
                    updateData.pdfFile = fileId;
                }));
            } else if (formDataZH) {
                tasks.push(uploadFile(formDataZH).then(fileId => {
                    updateData.pdfFile = fileId;
                }));
            }

            if (oldInfo.data.data.attributes.pdfFile_en.data && formDataEN) {
                const oldIdEN = oldInfo.data.data.attributes.pdfFile_en.data.id;
                const delInfoEN = await axios.delete(`${STRAPI}/api/upload/files/${oldIdEN}`, {
                    headers: {
                        Authorization: `Bearer ${STRAPI_token}`
                    },
                });

                if (delInfoEN.status !== 200) {
                    throw new Error('英文檔案刪除失敗');
                }

                tasks.push(uploadFile(formDataEN).then(fileId => {
                    updateData.pdfFile_en = fileId;
                }));
            } else if (formDataEN) {
                tasks.push(uploadFile(formDataEN).then(fileId => {
                    updateData.pdfFile_en = fileId;
                }));
            }

            await Promise.all(tasks);

            const connectResponse = await axios.put(
                url,
                {
                    data: updateData,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${STRAPI_token}`,
                    },
                }
            );

            if (connectResponse.status !== 200) {
                throw new Error('文件上傳有誤');
            }
        }
    } catch (error: any) {
        console.error('Error:', error.message);
        err++;
        errTxt = error.message;
    }

    if (err === 0) {
        return { status: true, info: 'Finished', oldInfo };
    } else {
        return { status: false, info: errTxt, oldInfo };
    }
};