import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import NavbarFront from '../../components/common/NavbarFront';
import HeaderFront from '../../components/common/HeaderFront';
import { DBsearch } from '../../api/DBsearch';
import CategoryList from '../../components/Product/CategoryList';
import { useLocation } from 'react-router-dom';
import ProductList from '../../components/Product/ProductList';
import ProductDetail from './ProductDetail';
import FooterFront from '../../components/common/FooterFront';

import { useTranslation } from 'react-i18next';
import ProductMaterial from '../../components/Product/ProductMaterial';

const Product: React.FC = () => {
  const { i18n } = useTranslation();
  const location = useLocation(); 

  const [categories, setCategories] = useState<any[]>([]);
  const [infoStatus, setInfoStatus] = useState<Boolean>(true);//false為productDetail
  const [products, setProducts] = useState<any[]>([]);

  //const [selectedCategoryTitle, setSelectedCategoryTitle] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedProduct,setSelectedProduct]=useState<any[]>([]);
  

  const showCategory = async () => {
    const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true },display:{type: 'basic', operator: 'eq', text: true} };
    const res = await DBsearch('systems','populate=*',searchStr, 'sort_ASC');
    setCategories(res.info);
  };

  const showProduct = async(seriesName:string|null)=>{
    if(seriesName){
      const sysNameTmp = seriesName.replace(/_/g, ' ');
      const sysName = sysNameTmp.replace(/__/g, '/');
      const searchStr={ nonDel: { type: 'basic', operator: 'eq', text: true },display:{type: 'basic', operator: 'eq', text: true},name_en: { type: 'advance', operator: 'containsi', text: sysName ,other:["system","name_en"]} };
      const res = await DBsearch('products','populate=*',searchStr, 'sort_ASC');
      setProducts(res.info);
      setInfoStatus(false);
    }else{
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true },display:{type: 'basic', operator: 'eq', text: true} };
      const res = await DBsearch('systems','populate=*',searchStr, 'sort_ASC');
      setProducts(res.info);
      setInfoStatus(true);
    }
  }

  const fetchProducts = async (categoryTitle: string) => {
    let searchStr;
    if(i18n.language==="zh"){
      searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true },name_zh: { type: 'advance', operator: 'eq', text: categoryTitle,other:["system","name_zh"]} };
    }else if(i18n.language==="en"){
      searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true },name_en: { type: 'advance', operator: 'containsi', text: categoryTitle,other:["system","name_en"]} };
    }
    const res = await DBsearch('products', `&populate=*`,searchStr);
    setSelectedProduct(res.info);
  };

  const handleSelectCategory = (slug: string, title: string) => {
    if(title){
      setSelectedCategory(title);
      //setSelectedCategoryTitle(title);
      fetchProducts(title);
    }
  };

  useEffect(() => {
    const seriesName = location.pathname.split('/')[2];
    showCategory();
    showProduct(seriesName);
  }, [location.pathname,infoStatus,setCategories,setProducts]);


  return(
    <>
      
      <NavbarFront />
      <HeaderFront />
      <div className='conatiner mt-4 mb-5 px-3 main-content'>
        <div className='row mb-1'>
          <div className='col-md-2'>
            {
              location.pathname.split('/')[2] && (
                <CategoryList 
                  categories={categories} 
                  onSelectCategory={handleSelectCategory}
                  selectedCategory={selectedCategory}
                  products={selectedProduct}
                />
              )
            }
          </div>
          <div className={location.pathname.split('/')[2] ? 'col-md-10' : 'col-md-12'}>
            <Routes>
              <Route index element={<ProductMaterial />} />
              <Route path=":productSystem" element={<ProductList products={products}  />} />
              <Route path=":productSystem/:productName" element={<ProductDetail products={products} />} />
            </Routes>
          </div>
        </div>
      </div>
      <div className='conatiner my-3'>
        &emsp;
      </div>
      
      <FooterFront />

    </>
  );
}

export default Product