import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import { DBsearch } from '../../api/DBsearch';
import { STRAPI } from '../../utils/config';

const CarouselTop:React.FC = () => {
    const [data, setData] = useState<any[]>([]);

    const showInfo = async()=>{
        const res = await DBsearch('carousels','populate=*',null,'sort_ASC');
        if(res.info){
            setData(res.info);
        }
    }

    useEffect(() => {
        showInfo();
    }, []);
    
    return (
        <Carousel>
            {
                data.length > 0 ? (
                    data.map((info: any, index: number) => {
                        let imgUrl;
                        if(info.attributes.image.data){
                            imgUrl = STRAPI + info.attributes.image.data.attributes.url;
                        }
                        
                        return (
                            <Carousel.Item key={index} interval={2000}>
                                <img
                                    className="d-block" width={"100%"}
                                    src={imgUrl}
                                    alt={`Slide ${index + 1}`}
                                />
                                <Carousel.Caption></Carousel.Caption>
                            </Carousel.Item>
                        );
                    })
                ) : (
                    <>
                    </>
                )
            }
        </Carousel>
    )
}

export default CarouselTop