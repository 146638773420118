import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getToken, isTokenExpired } from '../../utils/jwtUtils';

const PrivateRoute: React.FC = () => {
  const token = getToken();
  const isAuth = token && !isTokenExpired(token);

  return isAuth ? <Outlet /> : <Navigate to="/back" />;
};

export default PrivateRoute;