import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Container, Col, Card, Form, Button, Row } from 'react-bootstrap';
import { DBsearch } from '../../api/DBsearch';
import { DBupdate } from '../../api/DBupdate';
import { DBinsert } from '../../api/DBinsert';
import { DBuploadProduct } from '../../api/back/DBuploadProduct';

import Swal from 'sweetalert2';
import { STRAPI } from '../../utils/config';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { DBuploadDel } from '../../api/back/DBuploadDel';

interface DataState {
  chName: string;
  enName: string;
  display: boolean;
  system?: string;
  material?: string;
  image?: any;
  files?: any;
  files_en?: any;
}

const BackPEdit: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const [data, setData] = useState<DataState>({ chName: '', enName: '', display: true, system: '0',material:'0', image: [], files: [], files_en: [] });
  const [material, setMaterial] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
  const [uploadPhotoStatus, setUploadPhotoStatus] = useState<string>('');
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string | null>(null);

  const [selectedFileZH, setSelectedFileZH] = useState<File | null>(null);
  const [uploadFileStatusZH, setUploadFileStatusZH] = useState<string>('');
  const [uploadedFileUrlZH, setUploadedFileUrlZH] = useState<string | null>(null);

  const [selectedFileEN, setSelectedFileEN] = useState<File | null>(null);
  const [uploadFileStatusEN, setUploadFileStatusEN] = useState<string>('');
  const [uploadedFileUrlEN, setUploadedFileUrlEN] = useState<string | null>(null);

  const { state } = location as any;
  const id = state?.id;
  const dbName = state?.dbName;

  const backUrl = () => {
    let url;
    if (dbName && dbName === "systems") {
      url = "/dashboard/backPtype";
    } else if (dbName && dbName === "products") {
      url = "/dashboard/backPinfo";
    }
    return url;
  }

  const handleBack = () => {
    const urlWeb = backUrl();
    navigate(`${urlWeb}`);
  }

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedPhoto(event.target.files[0]);
    }
  };

  const handleFileChangeZH = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFileZH(event.target.files[0]);
    }
  };

  const handleFileChangeEN = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFileEN(event.target.files[0]);
    }
  };

  const showMaterial = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res: any = await DBsearch('materials', 'populate=*', searchStr);
      setMaterial(res.info);
    } catch (error) {
      console.error('Fetching product name failed:', error);
    }
  }

  const showCategory = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res: any = await DBsearch('systems', 'populate=*', searchStr);
      setCategory(res.info);
    } catch (error) {
      console.error('Fetching product name failed:', error);
    }
  }

  const handleRemoveDoc = async (doc: string) => {
    let fileId, fileEnId;
    const { files, files_en } = data;
  
    // 判斷刪除中文或英文文件
    if (doc === 'en') {
      if (files_en && files_en.data) {
        fileEnId = files_en.data.id;
      }
    } else {
      if (files && files.data) {
        fileId = files.data.id;
      }
    }
  
    try {
      // 顯示 Swal 確認對話框
      const confirm = await Swal.fire({
        title: '您確定要刪除這個項目嗎？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，刪除它！',
        cancelButtonText: '取消',
      });
  
      if (confirm.isConfirmed) {
        if (fileId) {
          const deleteRes = await DBuploadDel(fileId);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }
        if (fileEnId) {
          const deleteRes = await DBuploadDel(fileEnId);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }
  
        Swal.fire('刪除成功', '', 'success');
  
        showInfo(id);
      }
    } catch (error:any) {
      Swal.fire('刪除失敗', error.message, 'error');
    }
  };

  const showInfo = async (id: number) => {
    try {
      const searchStr = { id: { type: 'basic', operator: 'eq', text: id } };
      const res: any = await DBsearch(dbName, 'populate=*', searchStr);
      const { name_zh, name_en, display, files, files_en, image } = res.info[0].attributes;

      if (dbName === "systems") {
        //類別
        const { material } = res.info[0].attributes;
        if (material.data !== null) {
          setData({ chName: name_zh, enName: name_en, display: display, material: material.data.id, image, files, files_en });
        } else {
          setData({ chName: name_zh, enName: name_en, display: display, material: "0", image, files, files_en });
        }
      } else {
        const { system, files, files_en, image } = res.info[0].attributes;
        //產品
        if (system.data !== null) {
          setData({ chName: name_zh, enName: name_en, display: display, system: system.data.id, image, files, files_en });
        } else {
          setData({ chName: name_zh, enName: name_en, display: display, system: "0", image, files, files_en });
        }
      }

      if (files && files.data && files.data.attributes) {
        const fileUrlZH = STRAPI + files.data.attributes.url;
        setUploadedFileUrlZH(fileUrlZH);
      }else{
        setUploadedFileUrlZH(null);
      }


      if (files_en && files_en.data && files_en.data.attributes) {
        const fileUrlEN = STRAPI + files_en.data.attributes.url;
        setUploadedFileUrlEN(fileUrlEN);
      }else{
        setUploadedFileUrlEN(null);
      }

      if (image && image.data && image.data.attributes) {
        const imageUrl = STRAPI + image.data.attributes.url;
        setUploadedPhotoUrl(imageUrl);
      }else{
        setUploadedPhotoUrl(null);
      }

    } catch (error) {
      console.error('Fetching product name failed:', error);
    }
  }

  const handleSubmit = async () => {
    let err = 0;

    if (data.chName === "" || data.enName === "") {
      err++;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "必填項目未填寫。",
        showConfirmButton: false,
        timer: 1500
      });
    }

    if (dbName === 'systems' && data.material === "0") {
      err++;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "必填項目未填寫。",
        showConfirmButton: false,
        timer: 1500
      });
    }

    if (dbName === 'products' && data.system === "0") {
      err++;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "必填項目未填寫。",
        showConfirmButton: false,
        timer: 1500
      });
    }

    if (err === 0) {
      const dataObject: any = {
        name_zh: data.chName,
        name_en: data.enName,
        display: data.display,
      };

      if (dbName === 'systems') {
        dataObject.material = data.material;
      }

      if (dbName === 'products') {
        dataObject.system = data.system;
      }

      // 上傳圖片
      if (selectedPhoto) {
        if (id && data.image.data !== null) {
          //更新的部分，更新照片，需先刪除原照片
          const deleteRes = await DBuploadDel(data.image.data.id);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }

        const date = new Date();
        const formattedDate = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
        const newFileName = `${dbName}_${formattedDate}_${selectedPhoto.name}`;
        const photoFile = new File([selectedPhoto], newFileName, { type: selectedPhoto.type });

        const photoFormData = new FormData();
        photoFormData.append('files', photoFile);

        const photoUploadResponse = await DBuploadProduct(photoFormData);
        if (photoUploadResponse.status) {
          dataObject.image = photoUploadResponse.info[0].id;
          setUploadPhotoStatus('照片上傳成功');
        } else {
          setUploadPhotoStatus('照片上傳失敗');
        }
      }

      // 上傳中文文件
      if (selectedFileZH) {
        if (id && data.files.data !== null) {
          //更新的部分，更新文件，需先刪除原文件
          const deleteRes = await DBuploadDel(data.files.data.id);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }

        const date = new Date();
        const formattedDate = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
        const newFileName = `${dbName}_${formattedDate}_${selectedFileZH.name}`;
        const fileZH = new File([selectedFileZH], newFileName, { type: selectedFileZH.type });

        const fileFormDataZH = new FormData();
        fileFormDataZH.append('files', fileZH);

        const fileUploadResponseZH = await DBuploadProduct(fileFormDataZH);
        if (fileUploadResponseZH.status) {
          dataObject.files = fileUploadResponseZH.info[0].id;
          setUploadFileStatusZH('中文文件上傳成功');
        } else {
          setUploadFileStatusZH('中文文件上傳失敗');
        }
      }

      // 上傳英文文件
      if (selectedFileEN) {
        if (id && data.files_en.data !== null) {
          //更新的部分，更新文件，需先刪除原文件
          const deleteRes = await DBuploadDel(data.files_en.data.id);
          if (!deleteRes.status) {
            throw new Error(deleteRes.message);
          }
        }

        const date = new Date();
        const formattedDate = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
        const newFileName = `${dbName}_${formattedDate}_${selectedFileEN.name}`;
        const fileEN = new File([selectedFileEN], newFileName, { type: selectedFileEN.type });

        const fileFormDataEN = new FormData();
        fileFormDataEN.append('files', fileEN);

        const fileUploadResponseEN = await DBuploadProduct(fileFormDataEN);
        if (fileUploadResponseEN.status) {
          dataObject.files_en = fileUploadResponseEN.info[0].id;
          setUploadFileStatusEN('英文文件上傳成功');
        } else {
          setUploadFileStatusEN('英文文件上傳失敗');
        }
      }

      if (id) {
        // 更新
        const updateStr = {
          data: dataObject,
        };
        const res = await DBupdate(dbName, id, updateStr);
        if (res.info === 200) {
          Swal.fire({
            icon: "success",
            title: "更新成功",
            showConfirmButton: false,
            timer: 1500
          });
          const urlWeb = backUrl();
          navigate(`${urlWeb}`);
        }
      } else {
        // 插入
        const insertStr = {
          data: dataObject,
        };
        const res = await DBinsert(dbName, insertStr);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "新增成功",
            showConfirmButton: false,
            timer: 1500
          });
          const urlWeb = backUrl();
          navigate(`${urlWeb}`);
        }
      }
    }
  };

  useEffect(() => {
    if (id && dbName) {
      showInfo(id);
    }
    if (dbName === "products") {
      showCategory();
    }
    if (dbName === "systems") {
      showMaterial();
    }
  }, [id, dbName]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={12}>
          <h4 className='text-secondary'><b>{dbName === "systems" ? '類別資料' : '產品資料'} / {id ? '編輯頁面' : '新增頁面'}</b></h4>
        </Col>
      </Container>
      <Container className='pt-2' fluid>
        <Card className='border shadow-sm bg-light'>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <button type="button" onClick={() => handleBack()} aria-label="Go back"
                  style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }} >
                  <IoIosArrowDropleftCircle size={30} style={{ color: 'rgba(0,0,0,0.3)' }} />
                </button>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xs={12}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>中文名稱 *</b>
                  </label>
                  <input type='text' className="form-control" placeholder="請輸入中文名稱" value={data.chName} onChange={(e) => setData({ ...data, chName: e.target.value })} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>英文名稱 *</b>
                  </label>
                  <input type='text' className="form-control" placeholder="請輸入英文名稱" value={data.enName} onChange={(e) => setData({ ...data, enName: e.target.value })} />
                </div>
              </Col>
              {dbName === 'systems' && (
                <Col xs={12}>
                  <div className="mb-3 position-relative">
                    <label className="form-label text-dark">
                      <b>材質類別 *</b>
                    </label>
                    <select className="form-select" value={data.material} onChange={(e) => setData({ ...data, material: e.target.value })}>
                      <option value={0}>===選擇材質===</option>
                      {material.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.attributes.name_zh}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              )}
              {dbName === 'products' && (
                <Col xs={12}>
                  <div className="mb-3 position-relative">
                    <label className="form-label text-dark">
                      <b>類別 *</b>
                    </label>
                    <select className="form-select" value={data.system} onChange={(e) => setData({ ...data, system: e.target.value })}>
                      <option value={0}>===選擇類別===</option>
                      {category.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.attributes.name_zh}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              )}
              <Col xs={12}>
                <div className="mb-3 position-relative">
                  <label className="form-label text-dark">
                    <b>內容呈現</b>
                  </label>
                  <select className="form-select" value={data.display.toString()} onChange={(e) => setData({ ...data, display: e.target.value === 'true' })}>
                    <option value="true">呈現</option>
                    <option value="false">隱藏</option>
                  </select>
                </div>
              </Col>
              
            </Row>
              {
                dbName === "products" && (
                  <Row className='my-3'>
                    <Col md={6}>
                      <Form.Group controlId="formFileZH">
                        <Form.Label className='text-dark'><b>選擇上傳中文文件</b></Form.Label>
                        <span className='text-danger'>(上傳格式：pdf檔案)</span>
                        <Form.Control type="file" onChange={handleFileChangeZH} />
                      </Form.Group>
                      <span className='text-secondary'>(若檔案 中/英 文件只有一份上傳至此即可，上傳到英文文件前台不呈現。)</span>
                      <p className='text-danger'>{uploadFileStatusZH}</p>
                    </Col>
                    <Col md={6}>
                      {uploadedFileUrlZH ? (
                          <div className='mt-lg-4'>
                            <Button href={uploadedFileUrlZH} target="_blank" rel="noopener noreferrer" variant="outline-dark" className="text-decoration-none rounded p-2">
                              &ensp;<i className="bi bi-file-earmark-pdf text-danger" ></i> &ensp;
                              <span className="m-0">呈現當前中文pdf文件(開啟分頁)</span>&ensp;
                            </Button>
                            <Button variant='danger' className='m-2 btn-sm' onClick={()=>handleRemoveDoc('zh')}>刪除文件</Button>
                          </div>
                        ) : (
                          <div className='mt-lg-4'>
                            <Button target="_blank" rel="noopener noreferrer" variant="outline-secondary" className="text-decoration-none disabled rounded p-2">
                              &ensp;<i className="bi bi-file-earmark-pdf text-secondary" ></i> &ensp;
                              <span className="m-0">中文文件未上傳</span>&ensp;
                            </Button>
                          </div>
                        )}
                    </Col>
                  </Row>
                )
              }
              {
                dbName === "products" && (
                  <Row className='my-3'>
                    <Col md={6}>
                      <Form.Group controlId="formFileEN">
                        <Form.Label className='text-dark'><b>選擇上傳英文文件</b></Form.Label>
                        <span className='text-danger'>(上傳格式：pdf檔案)</span>
                        <Form.Control type="file" onChange={handleFileChangeEN} />
                      </Form.Group>
                      <p className='text-danger'>{uploadFileStatusEN}</p>
                    </Col>
                    <Col md={6}>
                      {uploadedFileUrlEN ? (
                          <div className='mt-lg-4'>
                            <Button href={uploadedFileUrlEN} target="_blank" rel="noopener noreferrer" variant="outline-dark" className="text-decoration-none rounded p-2">
                              &ensp;<i className="bi bi-file-earmark-pdf text-danger" ></i> &ensp;
                              <span className="m-0">呈現當前英文pdf文件(開啟分頁)</span>&ensp;
                            </Button>
                            <Button variant='danger' className='m-2 btn-sm' onClick={()=>handleRemoveDoc('en')}>刪除文件</Button>
                          </div>
                        ) : (
                          <div className='mt-lg-4'>
                            <Button target="_blank" rel="noopener noreferrer" variant="outline-secondary" className="text-decoration-none disabled rounded p-2">
                              &ensp;<i className="bi bi-file-earmark-pdf text-secondary" ></i> &ensp;
                              <span className="m-0">英文文件未上傳</span>&ensp;
                            </Button>
                          </div>
                        )}
                    </Col>
                  </Row>
                )
              }
              
            <Row className='my-3'>
              <Col md={6}>
                <Form.Group controlId="formFile">
                  <Form.Label className='text-dark'><b>選擇上傳照片</b></Form.Label>
                  <Form.Control type="file" onChange={handlePhotoChange} />
                </Form.Group>
                {/*<p className=''>{uploadPhotoStatus}</p>*/}
              </Col>
              <Col md={6}>
                {uploadedPhotoUrl ? (
                  <div className='imgUpload-div' role='button' onClick={() => setOpen(true)}>
                    <img src={uploadedPhotoUrl} className="img-fluid" alt="Uploaded" />
                  </div>
                ) : (
                  <div className='imgUpload-div'>
                    <span className="m-0">圖片未上傳</span>
                  </div>
                )}
              </Col>
            </Row>
            {
              uploadedPhotoUrl && <Lightbox open={open} close={() => setOpen(false)} slides={[{ src: uploadedPhotoUrl }]} />
            }
            <Row>
              <Col xs={12} className='text-center mt-2 mb-5'>
                <Button variant="outline-dark" className='px-4' onClick={handleSubmit}>
                  &emsp;送&ensp;出 &emsp;
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

export default BackPEdit;