import React, { useEffect, useState } from 'react';
import { Nav, Row, Col, Card, Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { STRAPI } from '../../utils/config';
import styles from './ProductMaterial.module.css';
import { DBsearchMaterial } from '../../api/front/DBsearchMaterial';
import { useNavigate } from 'react-router-dom'; 
import { DBsearch } from '../../api/DBsearch';

interface ProductMaterialProps {}

const ProductMaterial: React.FC<ProductMaterialProps> = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string | null>(null);
  const [materials, setMaterials] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);  // 當前頁數
  const [totalPages, setTotalPages] = useState<number>(1);    // 總頁數
  const itemsPerPage = 8;  // 每頁顯示的產品數量

  const showMaterial = async () => {
    const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true }, display: { type: 'basic', operator: 'eq', text: true } };
    const res = await DBsearch('materials', 'populate=*', searchStr, 'sort_ASC');
    setMaterials(res.info);
  };

  const showProduct = async (material: string | null = null, page: number = 1) => {
    if(material!==title){
        setTitle(material);
    }

    const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true }, display: { type: 'basic', operator: 'eq', text: true } };
    const res = await DBsearchMaterial(
      'products',
      'populate[system][populate][material]=true&populate[files]=true&populate[image]=true&populate[files_en]=true',
      searchStr,
      material,
      'sort_ASC'
    );

    const totalItems = res.pageMeta.pagination.total;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // 根據當前頁數切割數據，只顯示對應頁數範圍內的產品
    const startIdx = (page - 1) * itemsPerPage;
    const endIdx = page * itemsPerPage;
    const paginatedProducts = res.info.slice(startIdx, endIdx);  
    
    setProducts(paginatedProducts);
    setTotalPages(totalPages);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCardClick = (productSlug: any) => {
    const { name_en,system }=productSlug;
    const sysNameTmp=system.data.attributes.name_en;
    const sysName = sysNameTmp.replace(/\s+/g, '_');

    const productTmp=name_en.replace(/\s+/g, '_');
    const productName=productTmp.replace(/\//g, '__');

    navigate(`/product/${sysName}/${productName}`);
  };

  useEffect(() => {
    showMaterial();
    showProduct(title, currentPage);
  }, [currentPage]);

  return (
    <>
      <Row className='main-content'>
        <Row className="justify-content-center p-3">
          <Col>
            <Nav variant="pills" className="flex-row justify-content-center nav-horizontal">
              <Nav.Item>
                <Nav.Link
                  className={`nav-titleLink mt-2 mb-2 px-5 me-3 ${!title && 'active'}`}
                  onClick={() => showProduct(null, 1)}  // 點擊 'All' 時回到第一頁
                >
                  {i18n.language === 'en' ? 'All' : '全部'}
                </Nav.Link>
              </Nav.Item>
              {materials.length > 0 && materials.map((val: any, index: number) => {
                const { name_en, name_zh } = val.attributes;
                return (
                  <Nav.Item key={index}>
                    <Nav.Link
                      className={`nav-titleLink mt-2 mb-2 px-5 me-3 ${title === name_en && 'active'}`}
                      onClick={() => showProduct(name_en, 1)} 
                    >
                      {i18n.language === 'en' ? name_en : name_zh}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>
        </Row>
        <Row>
          {products.length > 0 && products.map((product, index) => (
            <Col lg={3} sm={12} className="d-flex align-items-stretch my-2" key={index}>
              <Card className={`${styles.productContant} product-card shadow-sm`} 
                role="button" onClick={() => handleCardClick(product.attributes)}>
                <Card.Body>
                  <Card.Img className="img-fluid pt-3" variant='top' src={`${STRAPI}${product.attributes.image?.data?.attributes.url}`} alt={product.attributes.name_en} />
                  <div className={`${styles.hoverOverlay}`}>
                    <h5 className='text-start'><b>{i18n.language === 'en' ? product.attributes.name_en : product.attributes.name_zh}</b></h5>
                    <p className="text-white ms-3 shadow-sm">
                      {i18n.language === 'en'
                        ? product.attributes.system?.data?.attributes.material?.data?.attributes.name_en
                        : product.attributes.system?.data?.attributes.material?.data?.attributes.name_zh}
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Col className="d-flex justify-content-center mt-4">
            <Pagination>
              <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
              <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
              <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ProductMaterial;
