import axios from 'axios';
import qs from 'qs';
import { STRAPI, STRAPI_token } from '../../utils/config';
import { transformKeys } from '../../utils/transofrmKeys';

interface DBResult {
  info: any[];
  pageMeta?: any;
  url?: any;
}

export const DBsearchMaterial = async (
  DBname: string,
  populateConn?: string,
  searchStr?: any,
  searchWord?:any,
  type?: string,
): Promise<DBResult> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${STRAPI_token}`,
      },
    };

    const query = qs.stringify({
      filters: transformKeys(searchStr, DBname).transformed,
      sort: getSortOrder(type),
      pagination: { page: 1, pageSize: 100 }, // 預設第一頁，25筆資料
    }, { encodeValuesOnly: true });

    let url = `${STRAPI}/api/${DBname}?${query}`;
    if (populateConn) {
      url += `&${populateConn}`;
    }
    if (searchWord) {
      url += `&filters[system][material][name_en][$eq]=${searchWord}`;
    }
    // 取得第一頁資料並確認總頁數
    const response = await axios.get(url, config);
    const { data, meta } = response.data;
    const totalPages = meta.pagination.pageCount;

    // 若只有一頁，直接回傳
    if (totalPages <= 1) return { info: data, pageMeta: meta };

    // 若有多頁，進行遞迴呼叫來取得所有資料
    const allData = await fetchAllPages(DBname, populateConn, searchStr, searchWord, type, totalPages, config);

    return { info: allData, pageMeta: meta };
  } catch (err) {
    console.error('Error setting up sync with remote database:', err);
    throw new Error('同步設置失敗');
  }
};

// 決定排序規則的輔助函數
const getSortOrder = (type?: string) => {
  if (type === 'search_id_ASC') return ['id:asc'];
  if (type === 'sort_ASC') return ['sort:asc', 'id:desc'];
  return ['id:desc'];
};

// 取得所有頁面的資料
const fetchAllPages = async (
  DBname: string,
  populateConn: string | undefined,
  searchStr: any,
  searchWord: string | undefined,
  type: string | undefined,
  totalPages: number,
  config: any
): Promise<any[]> => {
  const allData: any[] = [];

  for (let page = 1; page <= totalPages; page++) {
    const query = qs.stringify({
      filters: transformKeys(searchStr, DBname).transformed,
      sort: getSortOrder(type),
      pagination: { page, pageSize: 100 }, // 逐頁請求
    }, { encodeValuesOnly: true });

    let url = `${STRAPI}/api/${DBname}?${query}`;
    if (populateConn) {
      url += `&${populateConn}`;
    }
    if (searchWord) {
      url += `&filters[system][material][name_en][$eq]=${searchWord}`;
    }

    const response = await axios.get(url, config);
    allData.push(...response.data.data); // 將每頁的資料加入陣列
  }

  return allData;
};
