// CopyableText.tsx
import React, { useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomToast from './CustomToast';  // 确保导入CustomToast
import { useTranslation } from 'react-i18next';

interface CopyableTextProps {
  text: string;
  children?: React.ReactNode;
  tooltipText?: string;
}

const CopyableText: React.FC<CopyableTextProps> = ({ text, children, tooltipText }) => {
  const [showToast, setShowToast] = useState(false);
  const { t } = useTranslation();

  /*const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 1500);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };*/
  const copyToClipboard = async () => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        //alert('複製成功！');
      } else if (document.queryCommandSupported('copy')) {
        var textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = "fixed";  // 避免滾動到底部
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy'); 
          //alert('複製成功！');
        } catch (ex) {
          alert('複製失败，請手動複製。');
          console.error('Copy to clipboard failed.', ex);
        } finally {
          document.body.removeChild(textarea);
        }
      } else {
        alert('複製功能不支持，請手動複製。');
      }
    } catch (err) {
      console.error('Failed to copy:', err);
      alert('複製失敗: ' + err);
    }
  };

  return (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">{tooltipText || t('Click to copy')}</Tooltip>}>
        <div onClick={copyToClipboard} style={{ cursor: 'pointer', userSelect: 'none' }}>
          {children || <p>{text}</p>}
        </div>
      </OverlayTrigger>
      <CustomToast show={showToast} message={"["+t('copyText')+"] "+text} onClose={() => setShowToast(false)} />
    </>
  );
};

export default CopyableText;
