import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DBsearch } from '../../api/DBsearch';

import { Pie, Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timerId);
  }, []);

  return <h2>{time.toLocaleTimeString()}</h2>;
};

const DashBoardInfo: React.FC = () => {
  const [chartData, setChartData] = useState<any>(null);
  const [chartType, setChartType] = useState<string>('bar');

  const showCategoryContent = async () => {
    try {
      const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearch('systems', 'populate=*', searchStr, 'search_id_ASC');

      if (res && res.info && res.info.length > 0) {
        const labels = res.info.map((item: any) => item.attributes.name_zh);
        const data = res.info.map((item: any) => item.attributes.products.data.length);

        setChartData({
          labels,
          datasets: [
            {
              label: '數量',
              data,
              backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
              borderColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
              fill: false,
            },
          ],
        });
      }
    } catch (error) {
      console.error('獲取產品數據失敗:', error);
      return null;
    }
  };

  useEffect(() => {
    showCategoryContent();
  }, []);

  const renderChart = () => {
    if (!chartData) {
      return <div>加載中...</div>; 
    }

    switch (chartType) {
      case 'pie':
        return (
          <div className="chart-container">
            <Pie data={chartData} />
          </div>
        );
      case 'bar':
        return (
          <div className="chart-container">
            <Bar data={chartData} />
          </div>
        );

      default:
        return (
          <div className="chart-container">
            <Bar data={chartData} />
          </div>
        );
    }
  };

  return (
    <Container fluid>
      <Row className="my-2 pb-4">
        <Col>
          <h4 className="text-secondary"><b>Dashboard</b></h4>
        </Col>
      </Row>
      <Row>
        <Col lg={2} md={4}>
          <Row>
            <Card className="text-center text-dark shadow-sm border">
              <Card.Body>
                <Card.Title>當前時間</Card.Title>
                <Clock />
              </Card.Body>
            </Card>
          </Row>
          <Row className='mt-4'>
            <Card className="text-center text-dark shadow-sm border">
              <Card.Body>
                <h6>快速進入</h6>
                <Link to="/dashboard/backAbout" className="my-2">
                  <Button variant="outline-primary" className='px-lg-5 m-2'>關於我們</Button>
                </Link>
                <Link to="/dashboard/backNews" className="my-2">
                  <Button variant="outline-primary" className='px-lg-5 m-2'>文章分享</Button>
                </Link>
                <Link to="/dashboard/backPinfo" className="my-2">
                  <Button variant="outline-primary" className='px-lg-5 m-2'>產品資料</Button>
                </Link>
                <Link to="/dashboard/backPdf" className="my-2">
                  <Button variant="outline-primary" className='px-lg-5 m-2'>產品型錄</Button>
                </Link>
              </Card.Body>
            </Card>
          </Row>
        </Col>
        <Col lg={10} md={8}>
          <Card className="text-center text-dark shadow-sm bg-light text-center">
            <Card.Body>
              <Card.Title>產品資料數量</Card.Title>
              <Row>
                <Col lg={{offset:2,span:8}}>
                  <Form.Select aria-label="選擇圖表類型" className="mb-3" onChange={(e) => setChartType(e.target.value)} >
                    <option value="bar">直線線圖</option>
                    <option value="pie">圓餅圖</option>
                  </Form.Select>
                  {renderChart()}
                </Col>
              </Row>
                
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DashBoardInfo;