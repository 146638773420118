import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
//import { WEBSITE } from '../../utils/config';

const FooterFront:React.FC = () => {
    const { t } = useTranslation(); 

    return (
        <footer className="bg-dark text-light py-3">
            <Container>
            <Row>
                {/*<Col>
                    <img className='img-fluid' src={`${WEBSITE}/images/contact/logo.png`} width={100} />&emsp;&emsp;
                    <img className='img-fluid' src={`${WEBSITE}/images/contact/LOGO_ONLY_AI.png`} width={200} />
                </Col>*/}
                <Col className="text-end">
                    <Link to="/" className='btn btn-default text-white'>{t('Home')}</Link>
                    <Link to="/about" className='btn btn-default text-white'>{t('About Us')}</Link>
                    <Link to="/product" className='btn btn-default text-white'>{t('Product')}</Link>
                    <Link to="/contact" className='btn btn-default text-white'>{t('Contact Us')}</Link>
                    <Link to="/download" className='btn btn-default text-white'>{t('Download')}</Link>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col className="text-center">
                <p>版權所有 &copy; {new Date().getFullYear()} 捷和實業有限公司 Jetworld Speed Industries Co., Ltd.</p>
                </Col>
            </Row>
            </Container>
        </footer>
    )
}

export default FooterFront