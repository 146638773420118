import React, { useState, useEffect, useRef } from 'react';
import { Container, Col, Card, Row, Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DBsearch } from '../../api/DBsearch';
import { DBinsert } from '../../api/DBinsert';
import { DBdelete } from '../../api/DBdelete';
import { DBuploadDel } from '../../api/back/DBuploadDel';
import { DBupsort } from '../../api/DBupsort';
import { DBuploadMulti } from '../../api/back/DBuploadMulti';

import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import $ from 'jquery';
import 'datatables.net-responsive-dt';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { dataTableLang } from '../../utils/dataTableLang';
import { createRoot } from 'react-dom/client';

import { STRAPI } from '../../utils/config';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

DataTable.use(DT);

const BackCarousel: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [data, setData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [slides, setSlides] = useState<any[]>([]);
  const tableRef = useRef<any>(null);

  const columns = [
    { title: '', data: null },
    {
      title: '(點選圖片可放大查看)',
      data: 'imageUrl',
      render: (data: string) => `<img src="${data}" class="img-fluid fixed-size" alt="輪播圖" />`,
      className: 'text-center col-11',
    },
    { title: '', data: null },
  ];

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));
    }
  };

  const deleteMode = async (id: number, imgId: number) => {
    try {
      const deleteRes = await DBuploadDel(imgId);
      if (!deleteRes.status) {
        throw new Error(deleteRes.message);
      }

      const res = await DBdelete('carousels', id);
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'success',
          text: '刪除成功',
          showConfirmButton: false,
          timer: 1500,
        });
        showInfo();
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: '刪除失敗',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleOpenLightbox = (index: number) => {
    const slides = data.map((info) => ({
      src: `${STRAPI}${info.attributes.image.data.attributes.url}`,
    }));
    setSlides(slides);
    setOpen(true);
  };

  const showInfo = async () => {
    try {
      const res: any = await DBsearch('carousels', 'populate=*', null, 'sort_ASC');
      setData(res.info.length > 0 ? res.info : []);
    } catch (error) {
      console.error('Fetching carousel images failed:', error);
    }
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setUploadStatus('No files selected');
      return;
    }

    setIsUploading(true);

    const renamedFiles = selectedFiles.map(file => {
      const date = new Date();
      const formattedDate = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
      const newFileName = `carousels_${formattedDate}_${file.name}`;
      return new File([file], newFileName, { type: file.type });
    });

    const formData = new FormData();
    renamedFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('path', 'carousels');

    try {
      const res = await DBuploadMulti(formData);
      if (res.status === true) {
        const newImgData = res.info.map((file: { id: number }) => file.id);

        // 使用 Promise.all 来等待所有插入操作完成
        await Promise.all(newImgData.map(async (imgId: number) => {
          try {
            await DBinsert('carousels', { data: { image: [imgId] } });
          } catch (insertError) {
            console.error(`Failed to insert image with ID: ${imgId}`, insertError);
          }
        }));

        Swal.fire({
          icon: 'success',
          title: 'success',
          text: '上傳成功',
          showConfirmButton: false,
          timer: 1500,
        });

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        // 确保所有插入操作完成后再执行 showInfo
        showInfo();
        setSelectedFiles([]);
      }
    } catch (error) {
      console.error('Error:', error);
      setUploadStatus('上傳有誤');
    } finally {
      setIsUploading(false);
    }
  };

  const moveItem = async (index: number, direction: 'up' | 'down') => {
    const items = [...data];
    const [movedItem] = items.splice(index, 1);
    if (direction === 'up') items.splice(index - 1, 0, movedItem);
    else items.splice(index + 1, 0, movedItem);

    const storedLists = items.map((item, idx) => ({ id: item.id, sort: idx + 1 }));

    try {
      const response = await DBupsort('carousels', storedLists);
      if (response.info === 200) {
        showInfo();
        Swal.fire({
          icon: 'success',
          title: '排序更新成功',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: '排序更新失敗',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error updating carousel order:', error);
      Swal.fire({
        icon: 'error',
        title: '排序更新失敗',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    showInfo();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      $(tableRef.current).DataTable({
        responsive: true,
        data: data.map((item, index) => {
          const imageData = item.attributes.image?.data;
          const imageUrl = imageData ? `${STRAPI}${imageData.attributes.url}` : ''; 

          return {
            id: item.id,
            imgId: imageData?.id || null,
            imageUrl,
            sortIndex: index,
          };
        }),
        columns,
        ordering: false,
        searching: false,
        destroy: true,
        pageLength: 3,
        language: {
          ...dataTableLang,
          emptyTable: '目前尚未有圖片上傳',
        },
        headerCallback: (thead) => {
          $(thead).addClass('table-dark text-center');
        },
        createdRow: (row: any, rowData: any, index: number) => {
          const { id, imgId, sortIndex } = rowData;

          // Sorting buttons rendering
          const divSort = document.createElement('div');
          const rootSort = createRoot(divSort);
          rootSort.render(
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="outline-light"
                disabled={sortIndex === 0}
                onClick={() => moveItem(sortIndex, 'up')}
              >
                <i
                  className={`bi bi-arrow-up-circle-fill ${
                    sortIndex === 0 ? 'text-secondary' : 'text-primary'
                  }`}
                  style={{ fontSize: '1.25rem' }}
                ></i>
              </Button>
              &ensp;
              <Button
                variant="outline-light"
                disabled={sortIndex === data.length - 1}
                onClick={() => moveItem(sortIndex, 'down')}
              >
                <i
                  className={`bi bi-arrow-down-circle-fill ${
                    sortIndex === data.length - 1 ? 'text-secondary' : 'text-danger'
                  }`}
                  style={{ fontSize: '1.25rem' }}
                ></i>
              </Button>
            </div>
          );
          $(row).find('td').eq(0).empty().append(divSort);

          // Image click event
          $(row).find('td').eq(1).on('click', () => handleOpenLightbox(index));

          // Delete button rendering
          const divDelete = document.createElement('div');
          const rootDelete = createRoot(divDelete);
          rootDelete.render(
            <div className="d-flex justify-content-center align-items-center">
              <Button variant="outline-danger" onClick={() => deleteMode(id, imgId)}>
                <i className="bi bi-trash"></i>
              </Button>
            </div>
          );
          $(row).find('td').eq(2).empty().append(divDelete);
        },
        drawCallback: () => {
          $('div.dt-info').addClass('text-dark p-0');
          $('div.dt-layout-row').addClass('row mt-2 justify-content-between');
          $('div.dt-layout-start').addClass('col-md-auto me-auto');
          $('div.dt-layout-end').addClass('col-md-auto ms-auto');
          $('div.dt-paging nav').addClass('btn-group');

          setTimeout(() => {
            const buttons = $('button.dt-paging-button');
            buttons.each(function () {
              $(this).addClass('btn btn-secondary');
              if ($(this).hasClass('current')) {
                $(this).addClass('disabled').prop('disabled', true);
              }
            });
          }, 0);
        },
      });
      $('div.dt-length').hide();
    }
  }, [data]);

  return (
    <>
      <Container fluid>
        <Col xs={12} lg={2}>
          <h4 className="text-secondary">
            <b>輪播圖上傳</b>
          </h4>
        </Col>
      </Container>
      <Container className="pt-2" fluid>
        <Card className="border shadow-sm bg-light">
          <Card.Body>
            <Row className="mt-3">
              <Col md={10}>
                <Form.Group controlId="formFiles">
                  <Form.Label className="text-dark">
                    <b>選擇上傳圖片</b>
                  </Form.Label>
                  <Form.Control type="file" ref={fileInputRef} multiple onChange={handleFilesChange} />
                </Form.Group>
                <p className="text-secondary">
                  建議上傳檔案為.jpg 或 .png。因應前台版面所示，圖片比例建議為1369x428。
                </p>
                <p className="text-danger">{uploadStatus}</p>
              </Col>
              <Col md={2} className="d-flex justify-content-center align-items-center">
                <Button
                  variant="outline-dark"
                  className="w-100 p-1"
                  onClick={handleUpload}
                  disabled={isUploading}
                >
                  {isUploading ? <Spinner as="span" animation="border" size="sm" /> : '送出'}
                </Button>
              </Col>
            </Row>
            <Row className="text-dark mt-3">
              <Col xs={12}>
                <table ref={tableRef} className="table table-bordered table-hover" style={{ width: '100%' }} />
              </Col>
            </Row>
            {slides.length > 0 && <Lightbox open={open} close={() => setOpen(false)} slides={slides} />}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default BackCarousel;